import React, { useState, useEffect, useContext } from "react";
import {
  Drawer,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl, 
  InputLabel,
  CircularProgress,
  Tooltip
} from "@material-ui/core";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/Auth/AuthContext";

const filter = createFilterOptions({
  trim: true,
});

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 400,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  drawerTitle: {
    marginBottom: theme.spacing(3),
    fontWeight: 600,
    fontSize: "1.4rem",
    textAlign: 'center',
  },
  formControl: {
    marginTop: 16,
    minWidth: 120,
    width: "100%",
  },
  formControlHalf: {
    marginTop: 16,
    minWidth: 120,
    width: "50%",
  },
  btnWrapper: {
    position: "relative",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
    gap: theme.spacing(1)
  },
  button: {
    marginTop: 16,
    borderRadius: 13
  },
  messageQuickAnswersWrapper: {
    margin: 0,
    position: "relative",
    bottom: "0px",
    background: "#ffffff",
    padding: 20,
    border: "1px solid #e0e0e0", 
    borderRadius: 13,
    left: 0,
    width: "100%",
    maxHeight: "200px",
    overflowY: "auto",
    zIndex: 9999,
    "& li": {
      listStyle: "none",
      "& a": {
        display: "block",
        padding: "8px 12px", 
        textDecoration: "none",
        color: "#333",
        "&:hover": {
          backgroundColor: "#f5f5f5",
          cursor: "pointer",
        }
      }
    }
  }
}));

const ScheduledMessageModal = ({ open, onClose, message, onSave }) => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const [formData, setFormData] = useState(message || {});
  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [connections, setConnections] = useState([]);
  const [queues, setQueues] = useState([]);
  const [quickAnswers, setQuickAnswers] = useState([]);
  const [typeBar, setTypeBar] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchParam, setSearchParam] = useState("");
  const [selectedContact, setSelectedContact] = useState(null);
  const [newContact, setNewContact] = useState({});
  const [contactModalOpen, setContactModalOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: contactsData } = await api.get("/contacts");
        const { data: connectionsData } = await api.get("/whatsapp"); 
        const { data: queuesData } = await api.get("/queue");

        setContacts(contactsData.contacts);
        setConnections(Array.isArray(connectionsData) ? connectionsData : []);
        setQueues(Array.isArray(queuesData) ? queuesData : []);

        // Debug
        // console.log('Conexões:', connectionsData);
        // console.log('Filas:', queuesData); 

        if (!message) {
          const availableConnections = connectionsData || [];
          const availableQueues = queuesData || [];

          if (availableConnections.length === 1) {
            setFormData(prev => ({ ...prev, whatsappId: availableConnections[0].id }));
          }
          if (availableQueues.length === 1) {
            setFormData(prev => ({ ...prev, queueId: availableQueues[0].id }));
          }
        }
      } catch (err) {
        console.error('Erro ao carregar dados:', err);
        toastError(err);
      }
    };

    fetchData();
  }, [message]);

  useEffect(() => {
    if (!open || searchParam.length < 3) {
      setLoading(false);
      return;
    }
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("contacts", {
            params: { searchParam },
          });
          setOptions(data.contacts);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };

      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, open]);

  const createAddContactOption = (filterOptions, params) => {
    const filtered = filter(filterOptions, params);

    if (params.inputValue !== "" && !loading && searchParam.length >= 3) {
      filtered.push({
        name: `${params.inputValue}`,
      });
    }

    return filtered;
  };

  const renderOption = option => {
    if (option.number) {
      return `${option.name} - ${option.number}`; 
    } else {
      return `${i18n.t("newTicketModal.add")} ${option.name}`;
    }
  };

  const renderOptionLabel = option => {
    if (option.number) {
      return `${option.name} - ${option.number}`;
    } else {
      return `${option.name}`;
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      // Validar campos obrigatórios
      if (!formData.contactId) {
        toast.error("Selecione um contato");
        setLoading(false);
        return;
      }

      if (!formData.message) {
        toast.error("Digite uma mensagem");
        setLoading(false); 
        return;
      }

      if (!formData.scheduledAt) {
        toast.error("Selecione a data e hora do agendamento");
        setLoading(false);
        return;
      }

      if (!formData.whatsappId) {
        toast.error("Selecione uma conexão");
        setLoading(false);
        return;
      }


      const dataToSend = {
        ...formData,
        userId: user.id, 
        status: "PENDING",
      };

      if (message) {
        await api.put(`/scheduledMessages/${message.id}`, dataToSend);
        toast.success("Mensagem agendada atualizada com sucesso!");
      } else {
        await api.post("/scheduledMessages", dataToSend);
        toast.success("Mensagem agendada criada com sucesso!");
      }
      
      onSave(); 
    } catch (err) {
      toastError(err);
    }
    setLoading(false);
  };

  const handleChange = (field, value) => {
    setFormData((prevState) => ({ ...prevState, [field]: value }));
  };

  const handleLoadQuickAnswer = async (value) => {
    if (value && value.indexOf("/") === 0) {
      try {
        const { data } = await api.get("/quickAnswers/", {
          params: { searchParam: value.substring(1) },
        });
        setQuickAnswers(data.quickAnswers);
        if (data.quickAnswers.length > 0) {
          setTypeBar(true);
        } else {
          setTypeBar(false);
        }
      } catch (err) {
        setTypeBar(false);
      }
    } else {
      setTypeBar(false);
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      classes={{ paper: classes.drawer }}
    >
      <Typography className={classes.drawerTitle}>
        {message ? "Editar Mensagem Agendada" : "Nova Mensagem Agendada"}
      </Typography>

      <div style={{ display: "flex", flexDirection: "column", flex: 1, overflow: 'auto' }}>
        <TextField
          label="Data e Hora de Agendamento"
          type="datetime-local"
          variant="outlined"
          margin="normal"
          className={classes.formControl}
          InputLabelProps={{
            shrink: true,
          }}
          value={formData.scheduledAt || ""}
          onChange={(e) => handleChange("scheduledAt", e.target.value)}
        />

        <Autocomplete
          options={options}
          loading={loading}
          clearOnBlur
          autoHighlight
          freeSolo
          clearOnEscape
          getOptionLabel={renderOptionLabel}
          renderOption={renderOption}
          filterOptions={createAddContactOption}
          onChange={(e, newValue) => handleChange("contactId", newValue ? newValue.id : null)}
          renderInput={params => (
            <TextField
              {...params}
              label="Contato"
              variant="outlined"
              margin="normal"
              className={classes.formControl}
              onChange={e => setSearchParam(e.target.value)}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />

        <div style={{ display: "flex", gap: "16px" }}>
          <FormControl variant="outlined" className={classes.formControlHalf}>
            <InputLabel>Conexão</InputLabel>
            <Select
              value={formData.whatsappId || ""}
              onChange={(e) => handleChange("whatsappId", e.target.value)}
              label="Conexão"
            >
              {connections && connections.length > 0 ? (
                connections.map((conn) => (
                  <MenuItem key={conn.id} value={conn.id}>
                    {conn.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>Nenhuma conexão disponível</MenuItem>
              )}
            </Select>
          </FormControl>

          <FormControl variant="outlined" className={classes.formControlHalf}>
            <InputLabel>Setor</InputLabel>
            <Select
              value={formData.queueId || ""}
              onChange={(e) => handleChange("queueId", e.target.value)}
              label="Setor"
            >
              {queues && queues.length > 0 ? (
                queues.map((queue) => (
                  <MenuItem key={queue.id} value={queue.id}>
                    {queue.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>Nenhum setor disponível</MenuItem>
              )}
            </Select>
          </FormControl>
        </div>

        <Tooltip title="Digite / para selecionar uma Mensagem Rápida" placement="top">
          <TextField
            label="Mensagem"
            variant="outlined"
            margin="normal"
            fullWidth
            multiline
            rows={4}
            className={classes.formControl}
            value={formData.message || ""}
            onChange={(e) => {
              handleChange("message", e.target.value);
              handleLoadQuickAnswer(e.target.value);
            }}
            InputProps={{
              endAdornment: typeBar && (
                <div className={classes.messageQuickAnswersWrapper}>
                  <ul>
                    {quickAnswers.map((value, index) => (
                      <li key={index}>
                        <a onClick={() => {
                          handleChange("message", value.message);
                          setTypeBar(false);
                        }}>
                          {`${value.shortcut} - ${value.message}`}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )
            }}
          />
        </Tooltip>
      </div>

      <div className={classes.btnWrapper}>
        <Button
          onClick={onClose}
          color="secondary"
          variant="outlined"
          className={classes.button}
          disabled={loading}
        >
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          className={classes.button}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Salvar"}
        </Button>
      </div>
    </Drawer>
  );
};

export default ScheduledMessageModal;