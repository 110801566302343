import React, { useState, useEffect, useReducer, useCallback } from "react";
import { toast } from "react-toastify";
import openSocket from "../../services/socket-io";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from "@material-ui/core";

import {
  PlusCircle,
  Trash,
  PencilSimple,
  MagnifyingGlass,
  Tag
} from "phosphor-react";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import TagModal from "../../components/TagModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";

const reducer = (state, action) => {
  if (action.type === "LOAD_TAGS") {
    const tags = action.payload;
    const newTags = [];

    tags.forEach((tag) => {
      const tagIndex = state.findIndex((s) => s.id === tag.id);
      if (tagIndex !== -1) {
        state[tagIndex] = tag;
      } else {
        newTags.push(tag);
      }
    });

    return [...state, ...newTags];
  }

  if (action.type === "UPDATE_TAGS") {
    const tag = action.payload;
    const tagIndex = state.findIndex((s) => s.id === tag.id);

    if (tagIndex !== -1) {
      state[tagIndex] = tag;
      return [...state];
    } else {
      return [tag, ...state];
    }
  }

  if (action.type === "DELETE_TAG") {
    const tagId = action.payload;

    const tagIndex = state.findIndex((s) => s.id === tagId);
    if (tagIndex !== -1) {
      state.splice(tagIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    overflowY: "scroll",
    borderRadius: 13,
    ...theme.scrollbarStyles,
  },
  buttonSize: {
    maxWidth: "36px",
    maxHeight: "36px",
    padding: theme.spacing(1),
    borderRadius: 8
  },
  tableContainer: {
    borderRadius: 13,
    padding: theme.spacing(1),
    "& .MuiTableCell-root": {
      padding: theme.spacing(1),
    }
  },
  mainHeader: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    }
  },
  search: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    borderRadius: 13,
    '& .MagnifyingGlass': { // Ajusta a cor do ícone de pesquisa
      color: theme.palette.text.secondary,
    },
  },
  searchContainer: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  searchIcon: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    flex: 1,
    border: 'none',
    outline: 'none',
    padding: theme.spacing(1),
    backgroundColor: 'inherit', // Herda a cor de fundo do pai
    color: theme.palette.text.primary, // Usa a cor do texto do tema
    '&::placeholder': {
      color: theme.palette.text.secondary, // Cor do placeholder
    },
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      marginRight: theme.spacing(1)
    }
  },
  // remover o estilo colorDot pois não será mais usado
}));

const Tags = () => {
  const classes = useStyles();
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const [deletingTag, setDeletingTag] = useState(null);
  const [deletingAllTags, setDeletingAllTags] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [tags, dispatch] = useReducer(reducer, []);
  const [tagModalOpen, setTagModalOpen] = useState(false);

  const fetchTags = useCallback(async () => {
    try {
      const { data } = await api.get("/tags/", {
        params: { 
          searchParam: searchParam?.trim(), // Adicionado trim() para remover espaços
          pageNumber 
        },
      });
      // Filtro local case-insensitive para garantir
      const filteredTags = data.tags.filter(tag => 
        !searchParam?.trim() || 
        tag.name.toLowerCase().includes(searchParam.toLowerCase())
      );
      dispatch({ type: "LOAD_TAGS", payload: filteredTags });
      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  }, [searchParam, pageNumber]);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchTags();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, fetchTags]);

  useEffect(() => {
    const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

    socket.on("tags", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_TAGS", payload: data.tags });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_TAGS", payload: +data.tagId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleOpenTagModal = () => {
    setSelectedTag(null);
    setTagModalOpen(true);
  };

  const handleCloseTagModal = () => {
    setSelectedTag(null);
    setTagModalOpen(false);
  };

  const handleSearch = (event) => {
    // Removido toLowerCase() pois não é mais necessário
    setSearchParam(event.target.value);
  };

  const handleEditTag = (tag) => {
    setSelectedTag(tag);
    setTagModalOpen(true);
  };

  const handleDeleteTag = async (tagId) => {
    try {
      await api.delete(`/tags/${tagId}`);
      toast.success(i18n.t("tags.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingTag(null);
    setSearchParam("");
    setPageNumber(1);

    dispatch({ type: "RESET" });
    setPageNumber(1);
    await fetchTags();
  };

  const handleDeleteAllTags = async () => {
    try {
      await api.delete(`/tags`);
      toast.success(i18n.t("tags.toasts.deletedAll"));
    } catch (err) {
      toastError(err);
    }
    setDeletingAllTags(null);
    setSearchParam("");
    setPageNumber();

    dispatch({ type: "RESET" });
    setPageNumber(1);
    await fetchTags();
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          deletingTag ? `${i18n.t("tags.confirmationModal.deleteTitle")}`
          : `${i18n.t("tags.confirmationModal.deleteAllTitle")}`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => 
          deletingTag ? handleDeleteTag(deletingTag.id)
         : handleDeleteAllTags(deletingAllTags)
        }
      >
        {
          deletingTag ? `${i18n.t("tags.confirmationModal.deleteMessage")}`
            : `${i18n.t("tags.confirmationModal.deleteAllMessage")}`
        }
      </ConfirmationModal>
      <TagModal
        open={tagModalOpen}
        onClose={handleCloseTagModal}
        reload={fetchTags}
        aria-labelledby="form-dialog-title"
        tagId={selectedTag && selectedTag.id}
      />
      <MainHeader className={classes.mainHeader}>
        <Title >{i18n.t("tags.title")} ({tags.length})</Title>
        <MainHeaderButtonsWrapper>
          <Tooltip title={i18n.t("tags.buttons.add")}>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonSize}
              onClick={handleOpenTagModal}
            >
              <PlusCircle size={22} weight="bold" />
            </Button>
          </Tooltip>
          <Tooltip title={i18n.t("tags.buttons.deleteAll")}>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonSize}
              onClick={(e) => {
                setConfirmModalOpen(true);
                setDeletingAllTags(tags);
              }}
            >
              <Trash size={22} weight="bold" />
            </Button>
          </Tooltip>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      
      <div className={classes.searchContainer}>
        <div className={classes.search}>
          <MagnifyingGlass size={22} weight="bold" className={classes.searchIcon} />
          <input
            type="text"
            placeholder={i18n.t("contacts.searchPlaceholder")}
            className={classes.searchInput}
            value={searchParam}
            onChange={handleSearch}
          />
        </div>
      </div>

      <Paper
        className={`${classes.mainPaper} ${classes.tableContainer}`}
        variant="outlined"
        onScroll={handleScroll}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">{i18n.t("tags.table.color")}</TableCell>
              <TableCell align="center">{i18n.t("tags.table.name")}</TableCell>
              <TableCell align="center">{i18n.t("tags.table.contacts")}</TableCell>
              <TableCell align="center">{i18n.t("tags.table.actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {tags.map((tag) => (
                <TableRow key={tag.id}>
                  <TableCell align="center">
                    <div className={classes.customTableCell}>
                      <Tag 
                        size={22} 
                        weight="fill"
                        style={{ color: tag.color }}
                      />
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    {tag.name}
                  </TableCell>
                  <TableCell align="center">{tag.contacttag.length ? (<span>{tag.contacttag.length}</span>) : <span>0</span>}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => handleEditTag(tag)}
                    >
                      <PencilSimple size={22} weight="bold" style={{ color: theme.palette.primary.main }} />
                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={(e) => {
                        setConfirmModalOpen(true);
                        setDeletingTag(tag);
                      }}
                    >
                      <Trash size={22} weight="bold" style={{ color: theme.palette.primary.main }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={4} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Tags;