import { useTheme } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import React, { useEffect, useState } from "react";
import {
    Cell,
    Legend,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip,
} from "recharts";
import useTickets from "../../hooks/useTickets";
import { i18n } from "../../translate/i18n";
import CustomTooltip from "./CustomTooltip";
import Title from "./Title";

const ChartPerQueue = ({ searchParam, pageNumber, status, date, showAll, queueIds, withUnreadMessages }) => {
    const theme = useTheme();

    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const [selectedDate, setSelectedDate] = useState(getCurrentDate());
    const { tickets } = useTickets({
        searchParam,
        pageNumber,
        status,
        date: selectedDate,
        showAll,
        queueIds,
        withUnreadMessages,
    });

    const [queueChartData, setQueueChartData] = useState([]);
    
    useEffect(() => {
        const queueData = tickets.reduce((acc, ticket) => {
            const queueName = ticket.queue?.name || "Sem Setor";
            const queueColor = ticket.queue?.color || "#7C7C7C";

            if (!acc[queueName]) {
                acc[queueName] = { value: 0, color: queueColor };
            }
            acc[queueName].value++;
            return acc;
        }, {});

        const formattedData = Object.entries(queueData).map(([name, { value, color }]) => ({
            name,
            value,
            color,
        }));

        setQueueChartData(formattedData);
    }, [tickets]);

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    // Renderização personalizada do label
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const RADIAN = Math.PI / 180;
        const radius = outerRadius + 10; // Deslocar o texto para fora do gráfico
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return percent > 0.05 ? (
            <text
                x={x}
                y={y}
                fill={queueChartData[index].color} // Cor do texto igual à cor do gráfico
                textAnchor={x > cx ? 'start' : 'end'}
                dominantBaseline="central"
                style={{ fontSize: '12px', fontWeight: '500' }}
            >
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        ) : null;
    };

    return (
        <React.Fragment>
            <Title>{i18n.t("dashboard.chartPerQueue.perQueue.title")}</Title>
            <TextField
                label={i18n.t("dashboard.chartPerQueue.date.title")}
                type="date"
                value={selectedDate}
                onChange={handleDateChange}
                InputLabelProps={{
                    shrink: true,
                }}
                style={{ marginBottom: "16px" }}
            />
            <ResponsiveContainer width="100%" height={265}>
                <PieChart>
                    <Pie
                        data={queueChartData}
                        cx="40%"
                        cy="50%"
                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={80}
                        innerRadius={45}
                        fill={theme.palette.primary.main}
                        dataKey="value"
                        strokeWidth={3}
                        stroke="none" // Remover as bordas
                    >
                        {queueChartData.map((entry, index) => (
                            <Cell 
                                key={`cell-${index}`} 
                                fill={entry.color}
                                style={{
                                    filter: 'drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.2))',
                                }}
                            />
                        ))}
                    </Pie>
                    <Tooltip 
                        content={<CustomTooltip />} 
                        cursor={true}
                        animationDuration={200}
                    />
                    <Legend 
                        layout="vertical" 
                        align="right" 
                        verticalAlign="middle"
                        wrapperStyle={{
                            paddingLeft: "10px"
                        }}
                    />
                </PieChart>
            </ResponsiveContainer>
        </React.Fragment>
    );
};

export default ChartPerQueue;
