import React, { useState, useEffect } from 'react';
import Avatar from 'react-avatar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { getAvatarUrl } from '../../utils/avatarUtils';

const useStyles = makeStyles(() => ({
  smallAvatar: {
    width: '26px',
    height: '26px',
  },
}));

const UserAvatarSmall = ({ user, className = '' }) => {
  const classes = useStyles();
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchAvatar = async () => {
      if (!user?.id) return;
      
      setLoading(true);
      try {
        const url = getAvatarUrl(user) + `?t=${new Date().getTime()}`;
        setAvatarUrl(url);
      } catch (err) {
        console.error('Erro ao carregar avatar:', err);
        setAvatarUrl(null);
      } finally {
        setLoading(false);
      }
    };
    fetchAvatar();
  }, [user]);

  return (
    <Avatar
      name={user?.name || ""}
      size="26" 
      round={true}
      src={avatarUrl}
      className={`${classes.smallAvatar} ${className}`}
      key={user?.id}
      loading={loading}
    />
  );
};

UserAvatarSmall.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    avatar: PropTypes.string
  }).isRequired,
  className: PropTypes.string,
};

export default UserAvatarSmall;