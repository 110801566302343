import React, { useState, useContext, useRef, useEffect } from "react";
import {
  Drawer,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Tooltip
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 400,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  drawerTitle: {
    marginBottom: theme.spacing(3),
    fontWeight: 600,
    fontSize: "1.4rem",
    textAlign: 'center',
  },
  input: {
    marginBottom: 16,
  },
  dateTimeInput: {
    marginBottom: 16,
    marginTop: 16,
  },
  formControl: {
    marginTop: 16,
    minWidth: 120,
    width: "100%",
  },
  formControlHalf: {
    marginTop: 16,
    minWidth: 120,
    width: "50%",
  },
  btnWrapper: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    display: "flex", 
    justifyContent: "flex-end",
    gap: theme.spacing(1)
  },
  button: {
    marginTop: 16,
    borderRadius: 13
  },
  messageQuickAnswersWrapper: {
    margin: 0,
    position: "relative",
    bottom: "0px",
    background: "#ffffff",
    padding: 20,
    border: "1px solid #e0e0e0",
    borderRadius: 13,
    left: 0,
    width: "100%",
    maxHeight: "200px",
    overflowY: "auto",
    zIndex: 9999,
    "& li": {
      listStyle: "none",
      "& a": {
        display: "block",
        padding: "8px 12px",
        textDecoration: "none",
        color: "#333",
        "&:hover": {
          backgroundColor: "#f5f5f5",
          cursor: "pointer",
        }
      }
    }
  }
}));

const ScheduledMessageTicketModal = ({ modalOpen, onClose, initialContact, initialQueue, initialWhatsApp }) => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const isMounted = useRef(true);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [scheduledAt, setScheduledAt] = useState("");
  const [connections, setConnections] = useState([]);
  const [queues, setQueues] = useState([]);
  const [quickAnswers, setQuickAnswers] = useState([]);
  const [typeBar, setTypeBar] = useState(false);
  const [formData, setFormData] = useState({
    contactId: initialContact?.id,
    whatsappId: initialWhatsApp?.id,
    queueId: initialQueue?.id
  });

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: connectionsData } = await api.get("/whatsapp");
        const { data: queuesData } = await api.get("/queue");
        setConnections(connectionsData);
        setQueues(queuesData);
      } catch (err) {
        toastError(err);
      }
    };
    fetchData();
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const data = {
        message,
        scheduledAt,
        contactId: formData.contactId || initialContact.id,
        userId: user.id,
        whatsappId: formData.whatsappId || initialWhatsApp.id,
        queueId: formData.queueId || initialQueue.id,
        status: "PENDING"
      };

      await api.post("/scheduledMessages", data);
      toast.success(i18n.t("scheduledMessages.modal.success"));
      handleClose();
    } catch (err) {
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }
  };

  const handleClose = () => {
    onClose();
    if (isMounted.current) {
      setMessage("");
      setScheduledAt("");
    }
  };

  const handleChange = (field, value) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const handleLoadQuickAnswer = async (value) => {
    if (value && value.indexOf("/") === 0) {
      try {
        const { data } = await api.get("/quickAnswers/", {
          params: { searchParam: value.substring(1) },
        });
        setQuickAnswers(data.quickAnswers);
        setTypeBar(data.quickAnswers.length > 0);
      } catch (err) {
        setTypeBar(false);
      }
    } else {
      setTypeBar(false);
    }
  };

  return (
    <Drawer 
      anchor="right"
      open={modalOpen}
      onClose={handleClose}
      classes={{ paper: classes.drawer }}
    >
      <Typography className={classes.drawerTitle}>
        {i18n.t("scheduledMessages.modal.title")}
      </Typography>

      <div style={{ display: "flex", flexDirection: "column", flex: 1, overflow: 'auto' }}>
        <TextField
          label={i18n.t("scheduledMessages.modal.contact")}
          value={initialContact?.name || ""}
          disabled
          fullWidth
          variant="outlined"
          className={classes.input}
        />

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <FormControl variant="outlined" className={classes.formControlHalf}>
            <InputLabel>{i18n.t("scheduledMessages.modal.whatsapp")}</InputLabel>
            <Select
              value={formData.whatsappId || ""}
              onChange={(e) => handleChange("whatsappId", e.target.value)}
              label={i18n.t("scheduledMessages.modal.whatsapp")}
              disabled
            >
              {connections.map((connection) => (
                <MenuItem key={connection.id} value={connection.id}>
                  {connection.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="outlined" className={classes.formControlHalf}>
            <InputLabel>{i18n.t("scheduledMessages.modal.queue")}</InputLabel>
            <Select
              value={formData.queueId || ""}
              onChange={(e) => handleChange("queueId", e.target.value)}
              label={i18n.t("scheduledMessages.modal.queue")}
              disabled
            >
              {queues.map((queue) => (
                <MenuItem key={queue.id} value={queue.id}>
                  {queue.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <TextField
          label={i18n.t("scheduledMessages.modal.scheduledAt")}
          type="datetime-local"
          value={scheduledAt}
          onChange={(e) => setScheduledAt(e.target.value)}
          fullWidth
          variant="outlined"
          className={`${classes.input} ${classes.dateTimeInput}`}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <Tooltip title={i18n.t("scheduledMessages.modal.quickAnswerHelp")} placement="top">
          <TextField
            label={i18n.t("scheduledMessages.modal.message")}
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
              handleLoadQuickAnswer(e.target.value);
            }}
            fullWidth
            multiline
            rows={5}
            variant="outlined"
            className={classes.input}
            InputProps={{
              endAdornment: typeBar && (
                <div className={classes.messageQuickAnswersWrapper}>
                  <ul>
                    {quickAnswers.map((value, index) => (
                      <li key={index}>
                        <Button
                          onClick={() => {
                            setMessage(value.message);
                            setTypeBar(false);
                          }}
                          style={{
                            width: "100%",
                            textAlign: "left",
                            textTransform: "none"
                          }}
                        >
                          {`${value.shortcut} - ${value.message}`}
                        </Button>
                      </li>
                    ))}
                  </ul>
                </div>
              )
            }}
          />
        </Tooltip>
      </div>

      <div className={classes.btnWrapper}>
        <Button
          onClick={handleClose}
          color="secondary"
          disabled={loading}
          variant="outlined"
          className={classes.button}
        >
          {i18n.t("scheduledMessages.modal.cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={loading || !message || !scheduledAt}
          variant="contained"
          className={classes.button}
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            i18n.t("scheduledMessages.modal.save")
          )}
        </Button>
      </div>
    </Drawer>
  );
};

export default ScheduledMessageTicketModal;