import { makeStyles, useTheme } from "@material-ui/core/styles";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import openSocket from "../../services/socket-io";

import {
  Avatar,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from "@material-ui/core";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";

import { Can } from "../../components/Can";
import ConfirmationModal from "../../components/ConfirmationModal/";
import ContactModal from "../../components/ContactModal";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import NewTicketModalPageContact from "../../components/NewTicketModalPageContact";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Title from "../../components/Title";
import contactImageFallback from '../../assets/contact_image.webp';
import AlphabetTabs from "../../components/AlphabetTabs";

import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";

import { 
  PlusCircle,
  DownloadSimple, 
  Trash,
  PencilSimple,
  WhatsappLogo,
  ArrowsClockwise,
  MagnifyingGlass,
  UserPlus,
  Upload
} from "phosphor-react";

const reducer = (state, action) => {
  if (action.type === "LOAD_CONTACTS") {
    const contacts = action.payload;
    return [...contacts]; // Substitui completamente o estado
  }
  
  if (action.type === "RESET") {
    return [];
  }
  
  return state;
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    overflowY: "scroll",
    borderRadius: 13,
    ...theme.scrollbarStyles,
  },
  csvbtn: {
    textDecoration: 'none'
  },
  avatar: {
    width: "50px",
    height: "50px",
    borderRadius: "25%"
  },
  buttonSize: {
    maxWidth: "36px", 
    maxHeight: "36px",
    padding: theme.spacing(1),
    borderRadius: 8
  },
  tableContainer: {
    borderRadius: 13,
    padding: theme.spacing(1),
    "& .MuiTableCell-root": {
      padding: theme.spacing(1),
    }
  },
  tagsWrapper: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    borderRadius: 13,
  },
  mainHeader: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    }
  },
  responsiveButtons: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1)
    }
  },
  tableCell: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    }
  },
  search: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    borderRadius: 13,
    '& .MagnifyingGlass': { // Ajusta a cor do ícone de pesquisa
      color: theme.palette.text.secondary,
    },
  },
  searchIcon: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    flex: 1,
    border: 'none',
    outline: 'none',
    padding: theme.spacing(1),
    backgroundColor: 'inherit', // Herda a cor de fundo do pai
    color: theme.palette.text.primary, // Usa a cor do texto do tema
    '&::placeholder': {
      color: theme.palette.text.secondary, // Cor do placeholder
    },
  },
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1),
  },
  contentArea: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  }
}));

const Contacts = () => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [contacts, dispatch] = useReducer(reducer, []);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [deletingContact, setDeletingContact] = useState(null);
  const [deletingAllContact, setDeletingAllContact] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [contactTicket, setContactTicket] = useState({});
  const [filteredTags] = useState([]);
  const [allContacts, setAllContacts] = useState([]); // Novo estado
  const [currentTab, setCurrentTab] = useState("all");

  const theme = useTheme();
  const [loadingAll, setLoadingAll] = useState(false);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("/contacts/", {
            params: { 
              searchParam,
              pageNumber,
              pageSize: currentTab !== "all" ? "999999" : "100", // Carrega todos para filtro por letra
              getAllContacts: currentTab !== "all" // Indica ao backend para ignorar paginação
            },
          });

          dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
          setHasMore(data.hasMore);
        } catch (err) {
          toastError(err);
        }
        setLoading(false);
      };
      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, currentTab]);

  useEffect(() => {
    const socket = openSocket();

    socket.on("contact", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_CONTACTS", payload: data.contact });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_CONTACT", payload: +data.contactId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(true);
  };

  const handleCloseContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(false);
  };

  const handleCloseOrOpenTicket = (ticket) => {
    setNewTicketModalOpen(false);
    if (ticket !== undefined && ticket.id !== undefined) {
      history.push(`/tickets/${ticket.id}`);
    }
    setLoading(false);
  };

  const hadleEditContact = (contactId) => {
    setSelectedContactId(contactId);
    setContactModalOpen(true);
  };

  const handleDeleteContact = async (contactId) => {
    try {
      await api.delete(`/contacts/${contactId}`);
      toast.success(i18n.t("contacts.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingContact(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const handleDeleteAllContact = async () => {
    try {
      await api.delete("/contacts");
      toast.success(i18n.t("contacts.toasts.deletedAll"));
      history.go(0);
    } catch (err) {
      toastError(err);
    }
    setDeletingAllContact(null);
    setSearchParam("");
    setPageNumber();
  };

  const handleimportContact = async () => {
    try {
      await api.post("/contacts/import");
      history.go(0);
    } catch (err) {
      toastError(err);
    }
  };

  const handleCsvUpload = async (event) => {
    try {
      const file = event.target.files[0];
      if (!file) return;

      const formData = new FormData();
      formData.append('file', file);

      await api.post('/contacts/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      toast.success(i18n.t("contacts.toasts.uploaded"));
      history.go(0); 
    } catch (err) {
      toastError(err);
    }
  };

  const handleExportContacts = async () => {
    try {
      const { data } = await api.get("/contacts/", {
        params: { 
          searchParam: "",
          pageNumber: 1,
          pageSize: 999999 //número grande para pegar todos
        }
      });
      setAllContacts(data.contacts);
    } catch (err) {
      toastError(err);
    }
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const formatPhoneNumber = (number) => {
    if (number.startsWith('55') && number.length === 13) {
      const ddd = number.slice(2, 4);
      const firstPart = number.slice(4, 9);
      const secondPart = number.slice(9);
      return `(${ddd}) ${firstPart}-${secondPart}`;
    }

    return number;
  };

  const filterContactsByLetter = (contacts) => {
    if (currentTab === "all") return contacts;
    
    if (currentTab === "0-9") {
      return contacts.filter(contact => /^[0-9]/.test(contact.name.charAt(0).toUpperCase()));
    }
    
    return contacts.filter(contact => 
      contact.name.charAt(0).toUpperCase() === currentTab
    );
  };

  return (
    <MainContainer className={classes.mainContainer}>
      <NewTicketModalPageContact
        modalOpen={newTicketModalOpen}
        initialContact={contactTicket}
        onClose={(ticket) => {
          handleCloseOrOpenTicket(ticket);
        }}
      />
      <ContactModal
        open={contactModalOpen}
        onClose={handleCloseContactModal}
        aria-labelledby="form-dialog-title"
        contactId={selectedContactId}
      ></ContactModal>
      <ConfirmationModal
        title={
          deletingContact ? `${i18n.t("contacts.confirmationModal.deleteTitle")} ${deletingContact.name}?`
            : deletingAllContact ? `${i18n.t("contacts.confirmationModal.deleteAllTitle")}`
              : `${i18n.t("contacts.confirmationModal.importTitle")}`
        }
        open={confirmOpen}
        onClose={setConfirmOpen}
        onConfirm={(e) =>
          deletingContact ? handleDeleteContact(deletingContact.id)
            : deletingAllContact ? handleDeleteAllContact(deletingAllContact)
              : handleimportContact()
        }
      >
        {
          deletingContact ? `${i18n.t("contacts.confirmationModal.deleteMessage")}`
            : deletingAllContact ? `${i18n.t("contacts.confirmationModal.deleteAllMessage")}`
              : `${i18n.t("contacts.confirmationModal.importMessage")}`
        }
      </ConfirmationModal>
      <MainHeader className={classes.mainHeader}>
        <Title>{i18n.t("contacts.title")}</Title>
        <MainHeaderButtonsWrapper className={classes.responsiveButtons}>
          <Can
            role={user.profile}
            perform="drawer-admin-items:view"
            yes={() => (
              <>
                <Tooltip title={i18n.t("contacts.buttons.import")}>
                  <Button
                    variant="contained"
                    color="primary" 
                    className={classes.buttonSize}
                    onClick={(e) => setConfirmOpen(true)}
                  >
                    <ArrowsClockwise size={22} weight="bold" />
                  </Button>
                </Tooltip>
              </>
            )}
          />
          <Tooltip title={i18n.t("contacts.buttons.add")}>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonSize} 
              onClick={handleOpenContactModal}
            >
              <UserPlus size={22} weight="bold" />
            </Button>
          </Tooltip>
          <Can
            role={user.profile}
            perform="drawer-admin-items:view"
            yes={() => (
              <Tooltip title={i18n.t("contacts.buttons.export")}>
                <CSVLink
                  className={classes.csvbtn}
                  separator=";"
                  filename={'wasap-contacts.csv'}
                  data={allContacts.map((contact) => ({
                    name: contact.name,
                    number: contact.number,
                    email: contact.email
                  }))}
                  onClick={() => handleExportContacts()} // Chama a função antes de exportar
                  asyncOnClick={true}
                >
                  <Button
                    variant="contained"
                    color="primary"
                  >
                    <DownloadSimple size={22} weight="bold" />
                  </Button>
                </CSVLink>
              </Tooltip>
            )}
          />
          <Tooltip title={i18n.t("contacts.buttons.importcsv")}>
            <Button
              variant="contained"
              color="primary"
              component="label"
              className={classes.buttonSize}
            >
              <Upload size={22} weight="bold" />
              <input 
                type="file"
                hidden
                accept=".csv"
                onChange={handleCsvUpload}
              />
            </Button>
          </Tooltip>
          <Can
            role={user.profile}
            perform="drawer-admin-items:view"
            yes={() => (
              <>
                <Tooltip title={i18n.t("contacts.buttons.delete")}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.buttonSize}
                    onClick={(e) => {
                      setConfirmOpen(true);
                      setDeletingAllContact(contacts);
                    }}
                  >
                    <Trash size={22} weight="bold" />
                  </Button>
                </Tooltip>
              </>
            )}
          />
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <div className={classes.contentArea}>
        <div className={classes.search}>
          <MagnifyingGlass size={22} weight="bold" className={classes.searchIcon} />
          <input
            type="text"
            placeholder={i18n.t("contacts.searchPlaceholder")}
            className={classes.searchInput}
            value={searchParam}
            onChange={handleSearch}
          />
        </div>

        <AlphabetTabs 
          currentTab={currentTab}
          onChange={(newTab) => setCurrentTab(newTab)}
        />

        <Paper 
          className={`${classes.mainPaper} ${classes.tableContainer}`}
          variant="outlined" 
          onScroll={handleScroll}
        >
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" className={classes.tableCell} />
                <TableCell className={classes.tableCell}>
                  {i18n.t("contacts.table.name")}
                </TableCell>
                <TableCell align="center" className={classes.tableCell}>
                  {i18n.t("contacts.table.whatsapp")}
                </TableCell>
                <TableCell align="center" className={classes.tableCell}>
                  {i18n.t("contacts.table.email")}
                </TableCell>
                <TableCell align="center" className={classes.tableCell}>
                  {i18n.t("contacts.table.actions")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {filterContactsByLetter(contacts)
                  .filter((contact) => {
                    if (filteredTags.length === 0) return true;
                    return (
                      contact.tags &&
                      contact.tags.length > 0 &&
                      filteredTags.every(tag => contact.tags.some(ctag => ctag.id === tag.id))
                    );
                  })
                  .map((contact) => (
                    <TableRow key={contact.id}>
                      <TableCell style={{ paddingRight: 0 }} className={classes.tableCell}>
                        {<Avatar src={contact.profilePicUrl || contactImageFallback} className={classes.avatar} />}
                      </TableCell>
                      <TableCell className={classes.tableCell}>{contact.name}</TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        {user.isTricked === "enabled" ? formatPhoneNumber(contact.number) : formatPhoneNumber(contact.number).slice(0, -4) + "****"}
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>{contact.email}</TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={() => {
                            setContactTicket(contact);
                            setNewTicketModalOpen(true);
                          }}
                        >
                          <WhatsappLogo size={22} weight="bold" style={{ color: theme.palette.primary.main }} />
                        </IconButton>
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={() => hadleEditContact(contact.id)}
                        >
                          <PencilSimple size={22} weight="bold" style={{ color: theme.palette.primary.main }} />
                        </IconButton>
                        <Can
                          role={user.profile}
                          perform="contacts-page:deleteContact"
                          yes={() => (
                            <IconButton
                              size="small"
                              color="primary"
                              onClick={(e) => {
                                setConfirmOpen(true);
                                setDeletingContact(contact);
                              }}
                            >
                              <Trash size={22} weight="bold" style={{ color: theme.palette.primary.main }} />
                            </IconButton>
                          )}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                {loading && <TableRowSkeleton avatar columns={3} />}
              </>
            </TableBody>
          </Table>
        </Paper>
      </div>
    </MainContainer >
  );
};

export default Contacts;
