import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/Auth/AuthContext";
import useTickets from "../../hooks/useTickets";
import { i18n } from "../../translate/i18n";
import Chart from "./Chart";
import ChartPerConnection from "./ChartPerConnection";
//import ChartPerUser from "./ChartPerUser";
import ChartPerQueue from "./ChatsPerQueue";
//import ContactsWithTicketsChart from "./ContactsWithTicketsChart";
//import NewContactsChart from "./NewContactsChart";
//import TagCloud from "./TagCloud";
import { ArrowDown, ArrowUp, ClipboardText, HourglassMedium, CheckCircle } from "phosphor-react";
import ScheduledMessagesCalendar from "./ScheduledMessagesCalendar";

const useStyles = makeStyles(theme => ({
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	fixedHeightPaperCard: {
		padding: theme.spacing(1),
		backgroundColor: theme.palette.background.paper,
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: 150,
		alignItems: "center",
		justifyContent: "center",
		borderRadius: "13px",
		[theme.breakpoints.down('xs')]: {
			height: 100,
		},
	},
	fixedHeightPaper: {
		padding: theme.spacing(2),
		backgroundColor: theme.palette.background.paper,
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: 300,
		borderRadius: "13px",
		[theme.breakpoints.down('xs')]: {
			height: 200,
		},
	},
	customFixedHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "none",
		flexDirection: "column",
		height: 150,
		borderRadius: "13px",
	},
	largeHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: 400,
		borderRadius: "13px",
		[theme.breakpoints.down('xs')]: {
			height: 300,
		},
	},
	icon: {
		fontSize: 40,
		color: theme.palette.primary.main,
	},
	cardText: {
		fontSize: "2rem",
		marginLeft: theme.spacing(2),
		color: theme.palette.primary.main,
		fontWeight: 'bold',
	},
	arrowIcon: {
		marginLeft: theme.spacing(0.5),
		fontSize: "1rem",
	},
	cardTitle: {
		fontSize: "0.9rem",
		color: theme.palette.primary.dark,
		marginTop: theme.spacing(1),
	},
	cardContent: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'row',
		width: '100%',
	},
	noTagsContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "100px",
	},
	tagContainer: {
		display: "flex",
		flexWrap: "wrap",
		gap: "8px",
	},
	tagCounter: {
		marginLeft: "4px",
		color: "#666",
	},
	chip: {
		margin: "4px",
	},
}));

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: "rgba(255,255,255,0.95)",
          padding: "12px",
          border: "none",
          borderRadius: "8px",
          boxShadow: "0 4px 20px rgba(0,0,0,0.15)"
        }}
      >
        <p style={{ 
          margin: 0,
          color: "#333",
          fontWeight: 500 
        }}>
          {label}
        </p>
        {payload.map((p, i) => (
          <p
            key={i}
            style={{
              margin: "4px 0 0",
              color: p.color || "#666"
            }}
          >
            {p.name}: {p.value}
          </p>
        ))}
      </div>
    );
  }
  return null;
};

const Dashboard = () => {
	const classes = useStyles();
	const { user } = useContext(AuthContext);
	const userQueueIds = user.queues.map(q => q.id) || [];
	const [previousCounts, setPreviousCounts] = useState({
		inAttendance: 0,
		waiting: 0,
		closed: 0,
	});

	const ticketsInAttendance = useTickets({
		status: "open",
		showAll: "true",
		withUnreadMessages: "false",
		queueIds: JSON.stringify(userQueueIds),
	});

	const ticketsWaiting = useTickets({
		status: "pending",
		showAll: "true",
		withUnreadMessages: "false",
		queueIds: JSON.stringify(userQueueIds),
	});
	const ticketsClosed = useTickets({
		status: "closed",
		showAll: "true",
		withUnreadMessages: "false",
		queueIds: JSON.stringify(userQueueIds),
	});

	useEffect(() => {
		if (
			ticketsInAttendance.count !== previousCounts.inAttendance ||
			ticketsWaiting.count !== previousCounts.waiting ||
			ticketsClosed.count !== previousCounts.closed
		) {
			setPreviousCounts({
				inAttendance: ticketsInAttendance.count,
				waiting: ticketsWaiting.count,
				closed: ticketsClosed.count,
			});
		}
	}, [ticketsInAttendance.count, ticketsWaiting.count, ticketsClosed.count, previousCounts]);

	const renderChangeIcon = (current, previous) => {
		if (current > previous) {
			return <ArrowUp className={classes.arrowIcon} style={{ color: "green" }} />;
		} else if (current < previous) {
			return <ArrowDown className={classes.arrowIcon} style={{ color: "red" }} />;
		}

		if (current !== previous) {
			console.log("Nenhuma mudança significativa");
		}

		return null;
	};

	return (
		<Container maxWidth="lg" className={classes.container}>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6} md={4}>
					<Paper className={classes.fixedHeightPaperCard}>
						<div className={classes.cardContent}>
								<ClipboardText className={classes.icon} />
							<Typography component="h1" variant="h5" className={classes.cardText}>
								{ticketsInAttendance.count}
								{renderChangeIcon(ticketsInAttendance.count, previousCounts.inAttendance)}
							</Typography>
						</div>
						<Typography component="h3" className={classes.cardTitle}>
							{i18n.t("dashboard.messages.inAttendance.title")}
						</Typography>
					</Paper>
				</Grid>

				<Grid item xs={12} sm={6} md={4}>
					<Paper className={classes.fixedHeightPaperCard}>
						<div className={classes.cardContent}>
								<HourglassMedium className={classes.icon} />
							<Typography component="h1" variant="h5" className={classes.cardText}>
								{ticketsWaiting.count}
								{renderChangeIcon(ticketsWaiting.count, previousCounts.waiting)}
							</Typography>
						</div>
						<Typography component="h3" className={classes.cardTitle}>
							{i18n.t("dashboard.messages.waiting.title")}
						</Typography>
					</Paper>
				</Grid>

				<Grid item xs={12} sm={6} md={4}>
					<Paper className={classes.fixedHeightPaperCard}>
						<div className={classes.cardContent}>
								<CheckCircle className={classes.icon} />
							<Typography component="h1" variant="h5" className={classes.cardText}>
								{ticketsClosed.count}
								{renderChangeIcon(ticketsClosed.count, previousCounts.closed)}
							</Typography>
						</div>
						<Typography component="h3" className={classes.cardTitle}>
							{i18n.t("dashboard.messages.closed.title")}
						</Typography>
					</Paper>
				</Grid>
			</Grid>

			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper className={classes.fixedHeightPaper} style={{ height: 300 }}>
						<Chart tickets={ticketsInAttendance.tickets} />
					</Paper>
				</Grid>
			</Grid>

			<Grid container spacing={3}>
				<Grid item xs={12} md={6}>
					<Paper className={classes.largeHeightPaper}>
						<ChartPerConnection ticketsByConnection={ticketsInAttendance.tickets} />
					</Paper>
				</Grid>
				<Grid item xs={12} md={6}>
					<Paper className={classes.largeHeightPaper}>
						<ChartPerQueue ticketsByQueue={ticketsInAttendance.tickets} />
					</Paper>
				</Grid>
			</Grid>

			<Grid container spacing={3}>
 			    <Grid item xs={12}>
 			    	<Paper className={classes.fixedHeightPaper} style={{ height: "850px" }}>
   			        	<ScheduledMessagesCalendar />
   			    	</Paper>
   				</Grid>
			</Grid>
{/*
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper className={classes.fixedHeightPaper} style={{ height: 300 }}>
						<ChartPerUser ticketsByUser={ticketsInAttendance.tickets} />
					</Paper>
				</Grid>
			</Grid>

			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
					<Paper className={classes.fixedHeightPaper}>
						<NewContactsChart />
					</Paper>
				</Grid>
				
				<Grid item xs={12} sm={6}>
					<Paper className={classes.fixedHeightPaper}>
						<ContactsWithTicketsChart />
					</Paper>
				</Grid>
			</Grid>

			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper className={classes.fixedHeightPaper}>
						<TagCloud />
					</Paper>
				</Grid>
			</Grid>
*/}			
		</Container >
	);
};

export default Dashboard;