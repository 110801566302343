import React from 'react';

const Docs = () => {
    const iframeStyle = {
        width: "100%",
        height: "100%",
        minHeight: "100vh",
        border: "none",
        position: "absolute",
        top: 0,
        left: 0
    };

    return (
        <iframe 
            src="https://app.wasap.com.br/"
            style={iframeStyle}
            title="Documentação"
        />
    );
};

export default Docs;
