import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import WithSkeleton from "../WithSkeleton";
import MessageVariablesPicker from "../MessageVariablesPicker";
import ButtonWithSpinner from "../ButtonWithSpinner";
import FormikTextField from "../FormikTextField";

import {
  makeStyles,
  Button,
  Drawer,
  Typography,
  CircularProgress
} from "@material-ui/core";
import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 400,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  drawerHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(3)
  },
  drawerTitle: {
    marginBottom: theme.spacing(3),
    fontWeight: 600,
    fontSize: '1.4rem',
    textAlign: 'center'
  },
  btnWrapper: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    display: "flex", 
    justifyContent: "flex-end",
    gap: theme.spacing(1)
  },
  button: {
    marginTop: 16,
    borderRadius: 13
  },
  buttonProgress: {
    color: theme.palette.success.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  formContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto'
  }
}));

const QuickAnswerSchema = Yup.object().shape({
  shortcut: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  message: Yup.string()
    .min(8, "Too Short!")
    .max(30000, "Too Long!")
    .required("Required"),
});

const QuickAnswersModal = ({
  open,
  onClose,
  onSave,
  quickAnswerId,
  initialValues
}) => {
  const classes = useStyles();

  const initialState = {
    shortcut: "",
    message: "",
  };

  const isMounted = useRef(true);
  const messageInputRef = useRef();
  const [loading, setLoading] = useState(false);
  const [quickAnswer, setQuickAnswer] = useState(initialState);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (initialValues && isMounted.current) {
      setQuickAnswer(prevState => {
        return { ...prevState, ...initialValues };
      });
    }

    (async () => {
      if (!quickAnswerId) return;

      setLoading(true);
      try {
        const { data } = await api.get(`/quickAnswers/${quickAnswerId}`);
        if (!isMounted.current) return;

        setQuickAnswer(prevState => {
          return { ...prevState, ...data };
        });

        setLoading(false);
      } catch (err) {
        setLoading(false);
        toastError(err);
      }
    })();
    setQuickAnswer(initialState);
  }, [quickAnswerId, open, initialValues]);

  const handleSaveQuickAnswer = async values => {
    try {
      if (quickAnswerId) {
        await api.put(`/quickAnswers/${quickAnswerId}`, values);
        onClose();
      } else {
        const { data } = await api.post("/quickAnswers", values);
        if (onSave) {
          onSave(data);
        }
        onClose();
      }
      toast.success(i18n.t("quickAnswersModal.success"));
    } catch (err) {
      toastError(err);
    }
  };

  const handleClickMsgVar = async (msgVar, setValueFunc) => {
    const el = messageInputRef.current;
    const firstHalfText = el.value.substring(0, el.selectionStart);
    const secondHalfText = el.value.substring(el.selectionEnd);
    const newCursorPos = el.selectionStart + msgVar.length;

    setValueFunc("message", `${firstHalfText}${msgVar}${secondHalfText}`);

    await new Promise(r => setTimeout(r, 100));
    messageInputRef.current.setSelectionRange(newCursorPos, newCursorPos);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      classes={{ paper: classes.drawer }}
    >
      <Typography className={classes.drawerTitle}>
        {quickAnswerId
          ? i18n.t("quickAnswersModal.title.edit")
          : i18n.t("quickAnswersModal.title.add")}
      </Typography>

      <Formik
        initialValues={quickAnswer}
        enableReinitialize={true}
        validationSchema={QuickAnswerSchema}
        onSubmit={handleSaveQuickAnswer}
      >
        {({ touched, errors, isSubmitting, setFieldValue }) => (
          <Form className={classes.formContent}>
            <WithSkeleton loading={loading}>
              <FormikTextField
                label={i18n.t("quickAnswersModal.form.shortcut")}
                autoFocus
                name="shortcut"
                touched={touched}
                errors={errors}
                variant="outlined"
                margin="dense"
                disabled={isSubmitting}
              />
            </WithSkeleton>

            <WithSkeleton fullWidth loading={loading}>
              <FormikTextField
                label={i18n.t("quickAnswersModal.form.message")}
                multiline
                inputRef={messageInputRef}
                minRows={5}
                fullWidth
                name="message"
                touched={touched}
                errors={errors}
                variant="outlined"
                margin="dense"
                disabled={isSubmitting}
              />
            </WithSkeleton>

            <WithSkeleton loading={loading}>
              <MessageVariablesPicker
                disabled={isSubmitting}
                onClick={value => handleClickMsgVar(value, setFieldValue)}
              />
            </WithSkeleton>

            <div className={classes.btnWrapper}>
              <Button
                onClick={onClose}
                color="secondary"
                disabled={isSubmitting}
                variant="outlined"
                className={classes.button}
              >
                {i18n.t("quickAnswersModal.buttons.cancel")}
              </Button>
              <Button
                type="submit"
                color="primary"
                disabled={loading || isSubmitting}
                variant="contained"
                className={classes.button}
              >
                {isSubmitting ? (
                  <CircularProgress size={24} className={classes.buttonProgress} />
                ) : quickAnswerId ? (
                  i18n.t("quickAnswersModal.buttons.okEdit")
                ) : (
                  i18n.t("quickAnswersModal.buttons.okAdd")
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};

export default QuickAnswersModal;