import React, { useEffect, useReducer, useState, useCallback } from "react";
import openSocket from "../../services/socket-io";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Tooltip
} from "@material-ui/core";

import {
  PlusCircle,
  Trash,
  PencilSimple,
  MagnifyingGlass
} from "phosphor-react";

import ConfirmationModal from "../../components/ConfirmationModal";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import QueueModal from "../../components/QueueModal";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Title from "../../components/Title";

import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    overflowY: "scroll",
    borderRadius: 13,
    ...theme.scrollbarStyles,
  },
  buttonSize: {
    maxWidth: "36px",
    maxHeight: "36px",
    padding: theme.spacing(1),
    borderRadius: 8
  },
  tableContainer: {
    borderRadius: 13,
    padding: theme.spacing(1),
    "& .MuiTableCell-root": {
      padding: theme.spacing(1),
    }
  },
  mainHeader: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    }
  },
  search: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    borderRadius: 13,
    '& .MagnifyingGlass': { // Ajusta a cor do ícone de pesquisa
      color: theme.palette.text.secondary,
    },
  },
  searchContainer: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  searchIcon: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    flex: 1,
    border: 'none',
    outline: 'none',
    padding: theme.spacing(1),
    backgroundColor: 'inherit', // Herda a cor de fundo do pai
    color: theme.palette.text.primary, // Usa a cor do texto do tema
    '&::placeholder': {
      color: theme.palette.text.secondary, // Cor do placeholder
    },
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_QUEUES") {
    const queues = action.payload;
    const newQueues = [];

    queues.forEach((queue) => {
      const queueIndex = state.findIndex((q) => q.id === queue.id);
      if (queueIndex !== -1) {
        state[queueIndex] = queue;
      } else {
        newQueues.push(queue);
      }
    });

    return [...state, ...newQueues];
  }

  if (action.type === "UPDATE_QUEUES") {
    const queue = action.payload;
    const queueIndex = state.findIndex((u) => u.id === queue.id);

    if (queueIndex !== -1) {
      state[queueIndex] = queue;
      return [...state];
    } else {
      return [queue, ...state];
    }
  }

  if (action.type === "DELETE_QUEUE") {
    const queueId = action.payload;
    const queueIndex = state.findIndex((q) => q.id === queueId);
    if (queueIndex !== -1) {
      state.splice(queueIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const Queues = () => {
  const classes = useStyles();
  const theme = useTheme();

  const [queues, dispatch] = useReducer(reducer, []);
  const [loading, setLoading] = useState(false);

  const [queueModalOpen, setQueueModalOpen] = useState(false);
  const [selectedQueue, setSelectedQueue] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");

  const fetchQueues = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.get("/queue");
      const filteredQueues = data.filter(queue => 
        !searchParam?.trim() || 
        queue.name.toLowerCase().includes(searchParam.toLowerCase())
      );
      dispatch({ type: "LOAD_QUEUES", payload: filteredQueues });
    } catch (err) {
      toastError(err);
    }
    setLoading(false);
  }, [searchParam]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchQueues();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, fetchQueues]);

  useEffect(() => {
    const socket = openSocket();

    socket.on("queue", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_QUEUES", payload: data.queue });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_QUEUE", payload: data.queueId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleOpenQueueModal = () => {
    setQueueModalOpen(true);
    setSelectedQueue(null);
  };

  const handleCloseQueueModal = () => {
    setQueueModalOpen(false);
    setSelectedQueue(null);
  };

  const handleEditQueue = (queue) => {
    setSelectedQueue(queue);
    setQueueModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setSelectedQueue(null);
  };

  const handleDeleteQueue = async (queueId) => {
    try {
      await api.delete(`/queue/${queueId}`);
      toast.success(i18n.t("queues.notifications.queueDeleted"));
    } catch (err) {
      toastError(err);
    }
    setSelectedQueue(null);
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value);
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          selectedQueue &&
          `${i18n.t("queues.confirmationModal.deleteTitle")} ${
            selectedQueue.name
          }?`
        }
        open={confirmModalOpen}
        onClose={handleCloseConfirmationModal}
        onConfirm={() => handleDeleteQueue(selectedQueue.id)}
      >
        {i18n.t("queues.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <QueueModal
        open={queueModalOpen}
        onClose={handleCloseQueueModal}
        queueId={selectedQueue?.id}
      />
      <MainHeader className={classes.mainHeader}>
        <Title>{i18n.t("queues.title")} ({queues.length})</Title>
        <MainHeaderButtonsWrapper>
          <Tooltip title={i18n.t("queues.buttons.add")}>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonSize}
              onClick={handleOpenQueueModal}
            >
              <PlusCircle size={22} weight="bold" />
            </Button>
          </Tooltip>
        </MainHeaderButtonsWrapper>
      </MainHeader>

      <div className={classes.searchContainer}>
        <div className={classes.search}>
          <MagnifyingGlass size={22} weight="bold" className={classes.searchIcon} />
          <input
            type="text"
            placeholder={i18n.t("contacts.searchPlaceholder")}
            className={classes.searchInput}
            value={searchParam}
            onChange={handleSearch}
          />
        </div>
      </div>

      <Paper 
        className={`${classes.mainPaper} ${classes.tableContainer}`}
        variant="outlined"
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {i18n.t("queues.table.id")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("queues.table.name")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("queues.table.color")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("queues.table.greeting")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("queues.table.startWork")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("queues.table.endWork")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("queues.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {queues.map((queue) => (
                <TableRow key={queue.id}>
                  <TableCell align="center">{queue.id}</TableCell>
                  <TableCell align="center">{queue.name}</TableCell>
                  <TableCell align="center">
                    <div className={classes.customTableCell}>
                      <span
                        style={{
                          backgroundColor: queue.color,
                          width: 20,
                          height: 20,
                          alignSelf: "center",
                          borderRadius: 10
                        }}
                      />
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.customTableCell}>
                      <Typography
                        style={{ width: 300, align: "center" }}
                        noWrap
                        variant="body2"
                      >
                        {queue.greetingMessage}
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell align="center">{queue.startWork}</TableCell>
                  <TableCell align="center">{queue.endWork}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => handleEditQueue(queue)}
                    >
                      <PencilSimple size={22} weight="bold" style={{ color: theme.palette.primary.main }} />
                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={() => {
                        setSelectedQueue(queue);
                        setConfirmModalOpen(true);
                      }}
                    >
                      <Trash size={22} weight="bold" style={{ color: theme.palette.primary.main }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={7} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Queues;
