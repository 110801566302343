import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  IconButton,
  Tooltip
} from "@material-ui/core";

import {
  PlusCircle,
  PencilSimple,
  MagnifyingGlass,
  CheckCircle,
  Clock,
  CalendarX,
  WarningCircle
} from "phosphor-react";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import ScheduledMessageModal from "../../components/ScheduledMessageModal";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    overflowY: "scroll",
    borderRadius: 13,
    ...theme.scrollbarStyles,
  },
  buttonSize: {
    maxWidth: "36px",
    maxHeight: "36px",
    padding: theme.spacing(1),
    borderRadius: 8
  },
  tableContainer: {
    borderRadius: 13,
    padding: theme.spacing(1),
    "& .MuiTableCell-root": {
      padding: theme.spacing(1),
    }
  },
  search: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    borderRadius: 13,
    '& .MagnifyingGlass': { // Ajusta a cor do ícone de pesquisa
      color: theme.palette.text.secondary,
    },
  },
  searchContainer: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  searchInput: {
    flex: 1,
    border: 'none',
    outline: 'none',
    padding: theme.spacing(1),
    backgroundColor: 'inherit', // Herda a cor de fundo do pai
    color: theme.palette.text.primary, // Usa a cor do texto do tema
    '&::placeholder': {
      color: theme.palette.text.secondary, // Cor do placeholder
    },
  },
}));

const ScheduledMessages = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [searchParam, setSearchParam] = useState("");

  const getContactName = async (contactId) => {
    try {
      const { data } = await api.get(`/contacts/${contactId}`);
      return data.name;
    } catch (err) {
      return "Sem nome";
    }
  };

  const getUserName = async (userId) => {
    try {
      const { data } = await api.get(`/users/${userId}`);
      return data.name;
    } catch (err) {
      return "Desconhecido";
    }
  };

  const getConnectionName = async (whatsappId) => {
    try {
      const { data } = await api.get(`/whatsapp/${whatsappId}`);
      return data.name;
    } catch (err) {
      return "Desconhecido";
    }
  };

  const getSectorName = async (queueId) => {
    try {
      const { data } = await api.get(`/queue/${queueId}`);
      return data.name;
    } catch (err) {
      return "Desconhecido";
    }
  };

  const fetchMessages = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await api.get("/scheduledMessages");
      const messagesWithDetails = await Promise.all(
        data.map(async (message) => {
          const contactName = await getContactName(message.contactId);
          const userName = await getUserName(message.userId);
          const connection = await getConnectionName(message.whatsappId);
          const sector = await getSectorName(message.queueId);

          return {
            ...message,
            contactName,
            userName,
            connection,
            sector,
            // Remova a lógica antiga de status baseada em sent
          };
        })
      );
      setMessages(messagesWithDetails);
    } catch (err) {
      toast.error("Erro ao buscar mensagens agendadas");
    } finally {
      setLoading(false);
    }
  }, []); // Sem dependências pois usa apenas funções estáticas

  const handleCancelMessage = async (message) => {
    if (message.status !== "PENDING") return;
    
    try {
      await api.put(`/scheduledMessages/${message.id}/cancel`);
      toast.success(i18n.t("scheduledMessages.toasts.canceled"));
      await fetchMessages();
    } catch (err) {
      console.error(err);
      toast.error(i18n.t("scheduledMessages.toasts.cancelError"));
    }
  };

  useEffect(() => {
    fetchMessages();
  }, [fetchMessages]); 

  const handleOpenModal = () => {
    setSelectedMessage(null);
    setModalOpen(true);
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value);
  };

  
  const matchSearch = (value, searchTerm) => {
    if (!value) return false;
    return value.toString().toLowerCase().includes(searchTerm.toLowerCase());
  };

  
  const filterMessages = (message) => {
    if (!searchParam) return true;

    return (
      matchSearch(message.userName, searchParam) ||
      matchSearch(message.contactName, searchParam) ||
      matchSearch(message.message, searchParam) ||
      matchSearch(message.connection, searchParam) ||
      matchSearch(message.sector, searchParam) ||
      matchSearch(new Date(message.scheduledAt).toLocaleString('pt-BR'), searchParam) ||
      matchSearch(message.status, searchParam)
    );
  };

  const now = new Date();

  const upcomingMessages = messages.filter(
    (message) => new Date(message.scheduledAt) >= now
  );

  const pastMessages = messages.filter(
    (message) => new Date(message.scheduledAt) < now
  );

  const filteredUpcomingMessages = upcomingMessages.filter(filterMessages);
  const filteredPastMessages = pastMessages.filter(filterMessages).sort((a, b) => new Date(b.scheduledAt) - new Date(a.scheduledAt));

  const handleEditMessage = (message) => {
    setSelectedMessage(message);
    setModalOpen(true);
  };

  const StatusIcon = ({ status }) => {
    switch (status) {
      case 'CANCELED':
        return (
          <Tooltip title={i18n.t("scheduledMessages.status.canceled")}>
            <CalendarX size={22} weight="fill" color="#c4373c" />
          </Tooltip>
        );
      case 'SENT':
        return (
          <Tooltip title={i18n.t("scheduledMessages.status.sent")}>
            <CheckCircle size={22} weight="fill" color="#30d168" />
          </Tooltip>
        );
      case 'PENDING':
        return (
          <Tooltip title={i18n.t("scheduledMessages.status.pending")}>
            <Clock size={22} weight="fill" color="#7b8c8b" />
          </Tooltip>
        );
      default:
        return (
          <Tooltip title={i18n.t("scheduledMessages.status.error")}>
            <WarningCircle size={22} weight="fill" color="#c4373c" />
          </Tooltip>
        );
    }
  };

  return (
    <MainContainer>
      <MainHeader>
        <Title>Mensagens Agendadas</Title>
        <MainHeaderButtonsWrapper>
          <Tooltip title="Nova Mensagem">
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonSize}
              onClick={handleOpenModal}
            >
              <PlusCircle size={22} weight="bold" />
            </Button>
          </Tooltip>
        </MainHeaderButtonsWrapper>
      </MainHeader>

      <div className={classes.searchContainer}>
        <div className={classes.search}>
          <MagnifyingGlass size={22} weight="bold" style={{ marginRight: 8 }} />
          <input
            type="text"
            placeholder="Buscar mensagens..."
            className={classes.searchInput}
            value={searchParam}
            onChange={handleSearch}
          />
        </div>
      </div>

      <Title>Próximos Envios</Title>
      <Paper className={`${classes.mainPaper} ${classes.tableContainer}`} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">Atendente</TableCell>
              <TableCell align="center">Contato</TableCell>
              <TableCell align="center">Mensagem</TableCell>
              <TableCell align="center">Conexão/Setor</TableCell>
              <TableCell align="center">Data Agendada</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRowSkeleton columns={7} />
            ) : (
              filteredUpcomingMessages.map((message) => (
                <TableRow key={message.id}>
                  <TableCell align="center">{message.userName}</TableCell>
                  <TableCell align="center">{message.contactName}</TableCell>
                  <TableCell align="center">{message.message}</TableCell>
                  <TableCell align="center">{`${message.connection} / ${message.sector}`}</TableCell>
                  <TableCell align="center">
                    {`Dia ${new Date(message.scheduledAt).toLocaleString('pt-BR', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                    })} as ${new Date(message.scheduledAt).toLocaleString('pt-BR', {
                      hour: '2-digit',
                      minute: '2-digit'
                    })}`}
                  </TableCell>
                  <TableCell align="center">
                    <StatusIcon status={message.status} />
                  </TableCell>
                  <TableCell align="center">
                    <IconButton 
                      size="small" 
                      onClick={() => handleEditMessage(message)}
                      disabled={message.status !== "PENDING"}
                    >
                      <PencilSimple 
                        size={22} 
                        weight="bold" 
                        style={{ 
                          color: message.status !== "PENDING" ? "#ccc" : theme.palette.primary.main 
                        }} 
                      />
                    </IconButton>
                    <Tooltip title={message.status !== "PENDING" ? 
                      i18n.t("scheduledMessages.buttons.cantCancel") : 
                      i18n.t("scheduledMessages.buttons.cancel")
                    }>
                      <span>
                        <IconButton 
                          size="small"
                          onClick={() => handleCancelMessage(message)}
                          disabled={message.status !== "PENDING"}
                        >
                          <CalendarX 
                            size={22} 
                            weight="bold" 
                            style={{ 
                              color: message.status !== "PENDING" ? "#ccc" : "#FF0000" 
                            }} 
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </Paper>

      <Title>Histórico de Envios</Title>
      <Paper className={`${classes.mainPaper} ${classes.tableContainer}`} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">Atendente</TableCell>
              <TableCell align="center">Contato</TableCell>
              <TableCell align="center">Mensagem</TableCell>
              <TableCell align="center">Conexão/Setor</TableCell>
              <TableCell align="center">Data Agendada</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRowSkeleton columns={6} /> 
            ) : (
              filteredPastMessages.map((message) => (
                <TableRow key={message.id}>
                  <TableCell align="center">{message.userName}</TableCell>
                  <TableCell align="center">{message.contactName}</TableCell>
                  <TableCell align="center">{message.message}</TableCell>
                  <TableCell align="center">{`${message.connection} / ${message.sector}`}</TableCell>
                  <TableCell align="center">
                    {`Dia ${new Date(message.scheduledAt).toLocaleString('pt-BR', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                    })} as ${new Date(message.scheduledAt).toLocaleString('pt-BR', {
                      hour: '2-digit',
                      minute: '2-digit'
                    })}`}
                  </TableCell>
                    <TableCell align="center">
                      <StatusIcon status={message.status} />
                    </TableCell>
                  </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </Paper>

      <ScheduledMessageModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        message={selectedMessage}
        onSave={() => {
          fetchMessages(); 
          setModalOpen(false); 
        }}
      />
    </MainContainer>
  );
};

export default ScheduledMessages;