import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Tabs, Tab, Paper } from "@material-ui/core";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";

import GeneralSettings from "./components/GeneralSettings";
import IntegrationsSettings from "./components/IntegrationsSettings";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
  mainWrapper: {
    width: "100%",
    height: "100%", // Remove altura fixa
    backgroundColor: theme.palette.background.default,
    overflow: "visible" // Remove scroll interno
  },
  mainPaper: {
    borderRadius: 13,
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    margin: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1),
    }
  },
  tabsHeader: {
    backgroundColor: "transparent",
    borderBottom: `1px solid ${theme.palette.divider}`,
    '& .MuiTab-root': {
      minWidth: 120,
      padding: '16px 24px',
      fontWeight: 500,
      fontSize: '0.875rem',
      textTransform: 'none',
      transition: 'all 0.3s ease',
      color: theme.palette.text.secondary,
      '&.Mui-selected': {
        color: theme.palette.primary.main,
        fontWeight: 600
      },
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
        borderRadius: '8px 8px 0 0'
      }
    },
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.primary.main,
      height: 3,
      borderRadius: '3px 3px 0 0'
    }
  },
  settingsContent: {
    padding: theme.spacing(3),
    backgroundColor: "transparent"
  },
  tabPanel: {
    padding: theme.spacing(2, 0)
  }
}));

function TabPanel({ children, value, index }) {
  const classes = useStyles();
  
  return value === index ? (
    <div className={classes.tabPanel}>
      {children}
    </div>
  ) : null;
}

const Settings = () => {
  const classes = useStyles();
  const [tab, setTab] = useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <MainContainer>
      <div className={classes.mainWrapper}>
        <MainHeader>
          <Title>{i18n.t("settings.title")}</Title>
        </MainHeader>

        <Paper className={classes.mainPaper} elevation={0}>
          <Tabs
            value={tab}
            onChange={handleChange}
            variant="standard"
            className={classes.tabsHeader}
          >
            <Tab 
              label={i18n.t("settings.tabs.general")}
            />
            <Tab
              label={i18n.t("settings.tabs.integrations")} 
            />
          </Tabs>

          <div className={classes.settingsContent}>
            <TabPanel value={tab} index={0}>
              <GeneralSettings />
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <IntegrationsSettings />
            </TabPanel>
          </div>
        </Paper>
      </div>
    </MainContainer>
  );
};

export default Settings;