import React from 'react';
import { Chip, Tooltip, makeStyles } from '@material-ui/core';
import { system } from '../../config.json';
import { i18n } from '../../translate/i18n';
import ContactTag from '../ContactTag';
import { WhatsappLogo, TreeStructure, User, Tag } from "phosphor-react"; 

const useStyles = makeStyles(theme => ({
  bagContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  infoContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '4px',
    width: '100%',
  },
  tagContainer: {
    display: 'flex', 
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '4px',
  },
  chip: {
    fontSize: "0.8em",
    fontWeight: "bold",
    height: 16,
    padding: "5px 0px",
    position: "inherit",
    borderRadius: "3px",
    color: "white",
    marginRight: "5px",
    marginBottom: "3px",
  }
}));

const TicketBags = ({ ticket, userName, tags }) => {
  const classes = useStyles();

  return (
    <div className={classes.bagContainer}>
      <div className={classes.infoContainer}>
        {/* CONEXÃO */}
        {ticket.whatsappId && (
          <Tooltip title={i18n.t("ticketsList.items.connection")}>
            <Chip
              className={classes.chip}
              icon={<WhatsappLogo size={12} weight="fill" color="white" />} 
              style={{
                backgroundColor: ticket.whatsapp?.color || system.color.lightTheme.palette.primary,
              }}
              label={(ticket.whatsapp?.name || i18n.t("ticketsList.items.user")).toUpperCase()}
            />
          </Tooltip>
        )}

        {/* SETOR */}
        {ticket.queue && (
          <Tooltip title={i18n.t("ticketsList.items.queue")}>
            <Chip
              className={classes.chip} 
              icon={<TreeStructure size={12} weight="fill" color="white" />}
              style={{
                backgroundColor: ticket.queue.color || "#7C7C7C",
              }}
              label={ticket.queue.name.toUpperCase()}
            />
          </Tooltip>
        )}

        {/* USUÁRIO */}
        {userName && (
          <Tooltip title={i18n.t("ticketsList.items.user")}>
            <Chip
              className={classes.chip}
              icon={<User size={12} weight="fill" color="white" />}
              style={{
                backgroundColor: "black",
              }}
              label={userName.toUpperCase()}
            />
          </Tooltip>
        )}
      </div>

      {/* TAGS */}
      {tags?.length > 0 && (
        <div className={classes.tagContainer}>
          {tags.map((tag) => (
            <ContactTag 
              tag={{ ...tag, icon: <Tag size={12} weight="fill" color="white" /> }} 
              key={`ticket-contact-tag-${ticket.id}-${tag.id}`} 
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default TicketBags;