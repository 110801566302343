import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField
} from "@material-ui/core";
import { toast } from "react-toastify";
import api from "../../../services/api";
import { i18n } from "../../../translate/i18n";
import toastError from "../../../errors/toastError";

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    maxWidth: 900,
    marginBottom: theme.spacing(2),
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  card: {
    borderRadius: 13,
    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
    marginBottom: theme.spacing(3)
  },
  cardHeader: {
    backgroundColor: '#f0f0f0',
    padding: theme.spacing(2),
    borderBottom: '1px solid #e0e0e0'
  },
  cardContent: {
    padding: theme.spacing(3)
  },
  textField: {
    borderRadius: 13,
    '& .MuiOutlinedInput-root': {
      borderRadius: 13
    }
  }
}));

const IntegrationsSettings = () => {
  const classes = useStyles();
  const [integrations, setIntegrations] = useState([]);

  useEffect(() => {
    const fetchIntegrations = async () => {
      try {
        const { data } = await api.get("/integrations");
        setIntegrations(data);
      } catch (err) {
        toastError(err);
      }
    };
    fetchIntegrations();
  }, []);

  const handleChangeIntegration = async e => {
    const selectedValue = e.target.value;
    const integrationKey = e.target.name;

    try {
      await api.put(`/integrations/${integrationKey}`, {
        value: selectedValue,
      });
      setIntegrations(prevState => {
        const aux = [...prevState];
        const integrationIndex = aux.findIndex(s => s.key === integrationKey);
        aux[integrationIndex].value = selectedValue;
        return aux;
      });
      toast.success(i18n.t("integrations.success"));
    } catch (err) {
      toastError(err);
    }
  };

  const getIntegrationValue = key => {
    const integration = integrations.find(s => s.key === key);
    return integration ? integration.value : '';
  };

  return (
    <Container className={classes.container}>
      <Typography variant="h5" className={classes.sectionTitle}>
        {i18n.t("integrations.title")}
      </Typography>

      <Card className={classes.card}>
        <CardHeader 
          title={i18n.t("integrations.integrations.openai.title")}
          className={classes.cardHeader}
          titleTypographyProps={{variant: 'h6'}}
        />
        <CardContent className={classes.cardContent}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                className={classes.textField}
                variant="outlined"
                label={i18n.t("integrations.integrations.openai.organization")}
                name="organization"
                value={getIntegrationValue("organization")}
                onChange={handleChangeIntegration}
                autoComplete="organization"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                className={classes.textField}
                variant="outlined"
                label={i18n.t("integrations.integrations.openai.apikey")}
                name="apikey"
                type="password" 
                value={getIntegrationValue("apikey")}
                onChange={handleChangeIntegration}
                autoComplete="new-password"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card className={classes.card}>
        <CardHeader 
          title={i18n.t("integrations.integrations.n8n.title")}
          className={classes.cardHeader}
          titleTypographyProps={{variant: 'h6'}}
        />
        <CardContent className={classes.cardContent}>
          <TextField
            fullWidth
            className={classes.textField}
            variant="outlined"
            label={i18n.t("integrations.integrations.n8n.urlApiN8N")}
            name="urlApiN8N"
            value={getIntegrationValue("urlApiN8N")}
            onChange={handleChangeIntegration}
            autoComplete="off"
          />
        </CardContent>
      </Card>
    </Container>
  );
};

export default IntegrationsSettings;