const rules = {
  // Perfil "user" (atendente comum)
  user: {
    static: [], // Nenhuma permissão especial
  },

  // Perfil "admin" (administrador)
  admin: {
    static: [
      "drawer-admin-items:view",     // Ver itens admin no menu lateral
      "tickets-manager:showall",     // Ver todos os tickets
      "user-modal:editProfile",      // Editar perfis de usuário
      "user-modal:editQueues",       // Editar filas/setores
      "user-table:editTricked",      // Editar permissões especiais
      "ticket-options:deleteTicket", // Deletar tickets
      "ticket-options:transferWhatsapp", // Transferir entre WhatsApps
      "contacts-page:deleteContact", // Deletar contatos
    ],
  }
};

export default rules;