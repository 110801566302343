import React from 'react';
import { HexColorPicker } from 'react-colorful';
import { makeStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Popover,
  InputAdornment,
  TextField
} from '@material-ui/core';
import { Colorize } from '@material-ui/icons';
import { X } from "phosphor-react"; 

const useStyles = makeStyles((theme) => ({
  popover: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: 13,
    boxShadow: theme.shadows[3], 
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5,
    padding: 4,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  },
  colorPicker: {
    marginTop: theme.spacing(2) 
  },
  colorPreview: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    marginRight: 8
  },
  textField: {
    margin: theme.spacing(1, 0),
    borderRadius: 4,
    width: '100%',
    '& .MuiOutlinedInput-root': {
      borderRadius: 4,
      height: 56, 
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.divider,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.text.primary,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    }
  }
}));

const ColorPicker = ({ color, onChange }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <TextField
        label="Cor"
        onClick={handleClick}
        value={color}
        variant="outlined"
        margin="dense"
        className={classes.textField}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <div 
                className={classes.colorPreview} 
                style={{ backgroundColor: color }} 
              />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClick}>
                <Colorize />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.popover}>
          <IconButton 
            className={classes.closeButton}
            onClick={handleClose}
          >
            <X />
          </IconButton>
          <div className={classes.colorPicker}>
            <HexColorPicker
              color={color}
              onChange={onChange}
            />
          </div>
        </div>
      </Popover>
    </>
  );
};

export default ColorPicker;