import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box, Tooltip } from "@material-ui/core";
import { User, Hash, TreeStructure } from "phosphor-react";
import { i18n } from "../../translate/i18n";
import contactImageFallback from '../../assets/contact_image.webp';

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    padding: "12px 16px",
    alignItems: "center",
    maxHeight: "80px",
    cursor: "pointer",
    borderRadius: 13,
    backgroundColor: theme.palette.background.default,
    width: "100%"
  },
  avatar: {
    width: "50px", 
    height: "50px",
    borderRadius: "13px",
    objectFit: "cover",
    boxShadow: "0 2px 8px rgba(0,0,0,0.12)"
  },
  infoContainer: {
    marginLeft: 16,
    flex: 1,
    overflow: "hidden"
  },
  contactName: {
    fontWeight: 600,
    fontSize: "1.1rem",
    color: theme.palette.text.primary,
    marginBottom: 2
  },
  ticketInfo: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    color: theme.palette.text.secondary,
    fontSize: "0.9rem"
  },
  infoItem: {
    display: "flex",
    alignItems: "center",
    gap: "4px"
  }
}));

const TicketInfo = ({ contact, ticket, onClick }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container} onClick={onClick}>
      <img 
        src={contact.profilePicUrl || contactImageFallback}
        alt={contact.name}
        className={classes.avatar}
      />
      
      <Box className={classes.infoContainer}>
        <Typography noWrap className={classes.contactName}>
          <Tooltip title={`Nome do contato: ${contact.name}`}>
            <span>{contact.name}</span>
          </Tooltip>
        </Typography>

        <Box className={classes.ticketInfo}>
          <Tooltip title={`Ticket ID: ${ticket.id}`}>
            <Box className={classes.infoItem}>
              <Hash weight="bold" size={16} />
              {ticket.id}
            </Box>
          </Tooltip>

          {ticket.user && (
            <>
              <Tooltip title={`Atendente: ${ticket.user.name}`}>
                <Box className={classes.infoItem}>
                  <User weight="bold" size={16} />
                  {ticket.user.name}
                </Box>
              </Tooltip>

              <Tooltip title={`Setor: ${ticket.queue ? ticket.queue.name : i18n.t("messagesList.header.noQueue")}`}>
                <Box className={classes.infoItem}>
                  <TreeStructure weight="bold" size={16} /> 
                  {ticket.queue ? ticket.queue.name : i18n.t("messagesList.header.noQueue")}
                </Box>
              </Tooltip>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default TicketInfo;