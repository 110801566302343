import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Paper,
  Typography,
  Grid,
  FormControlLabel,
  Switch,
  Select,
  Tooltip,
  Card,
  CardHeader,
  CardContent,
  TextField
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import api from "../../../services/api";
import { i18n } from "../../../translate/i18n";
import toastError from "../../../errors/toastError";

// Estilos
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(4),
  },
  container: {
    width: "100%",
    maxWidth: 900,
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between", 
    borderRadius: 13,
    boxShadow: theme.shadows[3],
    backgroundColor: theme.palette.background.paper,
  },
  switchContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  selectControl: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  gridContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  card: {
    borderRadius: 13,
    boxShadow: '0 4px 6px rgba(0,0,0,0.1)', 
    marginBottom: theme.spacing(3)
  },
  cardHeader: {
    backgroundColor: '#f0f0f0',
    padding: theme.spacing(2),
    borderBottom: '1px solid #e0e0e0'
  },
  cardContent: {
    padding: theme.spacing(3)
  },
  textField: {
    borderRadius: 13,
    '& .MuiOutlinedInput-root': {
      borderRadius: 13
    }
  }
}));

// Switch personalizado
const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 13,
    backgroundColor: theme.palette.grey[400],
    opacity: 1,
  },
  checked: {},
}))((props) => <Switch {...props} />);

const GeneralSettings = () => {
  const classes = useStyles();
  const history = useHistory();
  const [settings, setSettings] = useState([]);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const { data } = await api.get("/settings");
        setSettings(data);
      } catch (err) {
        toastError(err);
      }
    };
    fetchSettings();
  }, []);

  const handleChangeBooleanSetting = async (e) => {
    const selectedValue = e.target.checked ? "enabled" : "disabled";
    const settingKey = e.target.name;

    try {
      await api.put(`/settings/${settingKey}`, { value: selectedValue });
      toast.success(i18n.t("settings.success"));
      history.go(0);
    } catch (err) {
      toastError(err);
    }
  };

  const handleChangeSetting = async (e) => {
    const selectedValue = e.target.value;
    const settingKey = e.target.name;

    try {
      await api.put(`/settings/${settingKey}`, { value: selectedValue });
      toast.success(i18n.t("settings.success"));
    } catch (err) {
      toastError(err);
    }
  };

  const getSettingValue = (key) => {
    const setting = settings.find((s) => s.key === key);
    return setting ? setting.value : "";
  };

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <Typography variant="h5" className={classes.sectionTitle}>
          {i18n.t("settings.title")}
        </Typography>
        
        <Grid container spacing={2} className={classes.gridContainer}>
          <Grid item xs={12} sm={6}>
            <div className={classes.switchContainer}>
              {["userCreation", "allTicket", "CheckMsgIsGroup", "call", "sideMenu"]
                .map((key) => (
                  <Paper className={classes.paper} key={key}>
                    <Tooltip title={i18n.t(`settings.settings.${key}.note`)}>
                      <FormControlLabel
                        control={
                          <IOSSwitch
                            checked={getSettingValue(key) === "enabled"}
                            onChange={handleChangeBooleanSetting}
                            name={key}
                          />
                        }
                        label={i18n.t(`settings.settings.${key}.name`)}
                      />
                    </Tooltip>
                  </Paper>
              ))}
            </div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <div className={classes.switchContainer}>
              {["quickAnswer", "closeTicketApi", "ASC", "created"]
                .map((key) => (
                  <Paper className={classes.paper} key={key}>
                    <Tooltip title={i18n.t(`settings.settings.${key}.note`)}>
                      <FormControlLabel
                        control={
                          <IOSSwitch
                            checked={getSettingValue(key) === "enabled"}
                            onChange={handleChangeBooleanSetting}
                            name={key}
                          />
                        }
                        label={i18n.t(`settings.settings.${key}.name`)}
                      />
                    </Tooltip>
                  </Paper>
              ))}
            </div>
          </Grid>
        </Grid>

        {/* Configurações de tempo */}
        <Container className={classes.container}>
          <div className={classes.selectControl}>
            <Tooltip title={i18n.t("settings.settings.hoursCloseTicketsAuto.note")}>
              <Paper className={classes.paper}>
                <Typography variant="body1">
                  {i18n.t("settings.settings.hoursCloseTicketsAuto.name")}
                </Typography>
                <Select
                  native
                  value={getSettingValue("hoursCloseTicketsAuto")}
                  onChange={handleChangeSetting}
                  name="hoursCloseTicketsAuto"
                >
                  {[1, 2, 4, 8, 12, 24, 48].map((hours) => (
                    <option key={hours} value={hours}>{`${hours} hora${hours > 1 ? 's' : ''}`}</option>
                  ))}
                </Select>
              </Paper>
            </Tooltip>
          </div>
        </Container>

        <Container className={classes.container}>
          <div className={classes.selectControl}>
            <Tooltip title={i18n.t("settings.settings.timeCreateNewTicket.note")}>
              <Paper className={classes.paper}>
                <Typography variant="body1">
                  {i18n.t("settings.settings.timeCreateNewTicket.name")}
                </Typography>
                <Select
                  native
                  value={getSettingValue("timeCreateNewTicket")}
                  onChange={handleChangeSetting}
                  name="timeCreateNewTicket"
                >
                  {[10, 30, 60, 300, 1800, 3600, 7200, 21600, 43200, 86400, 604800, 1296000, 2592000]
                    .map((value) => (
                      <option key={value} value={value}>
                        {i18n.t(`settings.settings.timeCreateNewTicket.options.${value}`)}
                      </option>
                  ))}
                </Select>
              </Paper>
            </Tooltip>
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default GeneralSettings;