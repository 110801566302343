import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { Timer } from 'phosphor-react';

const useStyles = makeStyles((theme) => ({
  trialBox: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 12px',
    border: '1px solid #fff',
    borderRadius: 13,
    marginRight: theme.spacing(2),
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.9
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  text: {
    color: '#fff',
    marginLeft: theme.spacing(1),
    fontSize: '0.875rem'
  },
  icon: {
    color: '#fff'
  }
}));

const TrialDays = () => {
  const classes = useStyles();
  const [daysLeft, setDaysLeft] = useState(null);

  useEffect(() => {
    if (process.env.REACT_APP_TESTE !== 'ON') return;

    const calculateDays = () => {
      const endDate = process.env.REACT_APP_TESTE_DATA;
      if (!endDate) return;

      const [day, month, year] = endDate.split('/');
      const trialEnd = new Date(year, month - 1, day);
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      trialEnd.setHours(0, 0, 0, 0);

      const diffTime = trialEnd.getTime() - today.getTime();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      setDaysLeft(diffDays);
    };

    calculateDays();
  }, []);

  if (process.env.REACT_APP_TESTE !== 'ON' || daysLeft === null) return null;

  return (
    <Box
      component="a"
      href="https://api.whatsapp.com/send?phone=5554981346623"
      target="_blank"
      rel="noopener noreferrer"
      className={classes.trialBox}
      style={{ textDecoration: 'none' }}
    >
      <Timer size={18} className={classes.icon} />
      <Typography className={classes.text}>
        {daysLeft === 0 
          ? "Seu teste acaba hoje. Contrate agora!"
          : `Faltam ${daysLeft} dias para o fim do teste!`
        }
      </Typography>
    </Box>
  );
};

export default TrialDays;