import React, { useState, useEffect, useContext } from "react";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import {
  Drawer,
  Button,
  TextField,
  Typography,
  CircularProgress,
  Grid
} from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import ColorPicker from "../ColorPicker";
import * as Yup from "yup";

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 400,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  drawerHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(3)
  },
  drawerTitle: {
    fontWeight: 600,
    fontSize: '1.4rem',
    textAlign: 'center'
  },
  btnWrapper: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    display: "flex", 
    justifyContent: "flex-end",
    gap: theme.spacing(1)
  },
  button: {
    marginTop: 16,
    borderRadius: 13
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1, 0),
    minWidth: 120,
  },
  textField: {
    marginBottom: theme.spacing(2),
    width: '100%'
  },
  colorPickerContainer: {
    marginTop: theme.spacing(1),
  },
}));

const TagSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Mensagem muito curta")
    .required("Obrigatório"),
  color: Yup.string()
    .required("Obrigatório"),
});

const initialState = {
  name: "",
  color: "#000000"
};

const TagModal = ({ open, onClose, tagId, reload }) => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const [tag, setTag] = useState(initialState);
  const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);

  useEffect(() => {
    const fetchTag = async () => {
      if (!tagId) return;
      try {
        const { data } = await api.get(`/tags/${tagId}`);
        setTag(data);
      } catch (err) {
        toastError(err);
      }
    };
    fetchTag();
  }, [tagId]);

  const handleClose = () => {
    setTag(initialState);
    setColorPickerModalOpen(false);
    onClose();
  };

  const handleSaveTag = async (values) => {
    const tagData = { ...values, userId: user.id };
    try {
      if (tagId) {
        await api.put(`/tags/${tagId}`, tagData);
      } else {
        await api.post("/tags", tagData);
      }
      toast.success(i18n.t("tagModal.success"));
      if (typeof reload == "function") {
        reload();
      }
    } catch (err) {
      toastError(err);
    }
    handleClose();
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.drawer }}
    >
      <div className={classes.drawerHeader}>
        <Typography className={classes.drawerTitle}>
          {tagId
            ? i18n.t("tagModal.title.edit")
            : i18n.t("tagModal.title.add")}
        </Typography>
      </div>

      <Formik
        initialValues={tag}
        enableReinitialize={true}
        validationSchema={TagSchema}
        onSubmit={(values, actions) => {
          handleSaveTag(values);
          actions.setSubmitting(false);
        }}
      >
        {({ touched, errors, isSubmitting, values, setFieldValue }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  label={i18n.t("tagModal.form.name")}
                  name="name"
                  variant="outlined"
                  fullWidth
                  className={classes.textField}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Grid>
              <Grid item xs={12}>
                <Field name="color">
                  {({ field }) => (
                    <ColorPicker
                      label={i18n.t("tagModal.form.color")}
                      color={field.value}
                      onChange={(color) => setFieldValue('color', color)}
                    />
                  )}
                </Field>
              </Grid>
            </Grid>

            <div className={classes.btnWrapper}>
              <Button
                onClick={handleClose}
                color="secondary"
                variant="outlined"
                className={classes.button}
                disabled={isSubmitting}
              >
                {i18n.t("tagModal.buttons.cancel")}
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                className={classes.button}
                disabled={isSubmitting}
              >
                {tagId
                  ? i18n.t("tagModal.buttons.okEdit")
                  : i18n.t("tagModal.buttons.okAdd")}
                {isSubmitting && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};

export default TagModal;