import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography, IconButton, Tooltip, Box } from "@material-ui/core";
import { CaretLeft, CaretRight } from "phosphor-react";
import { i18n } from "../../translate/i18n";
import Title from "./Title";
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  calendarContainer: {
    padding: theme.spacing(2),
    borderRadius: 13,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    flex: "0 0 auto",
  },
  weekDays: {
    display: "grid",
    gridTemplateColumns: "repeat(7, 1fr)",
    gap: "4px",
    marginBottom: theme.spacing(1),
    flex: "0 0 auto",
    "& > div": {
      textAlign: "center",
      padding: theme.spacing(1),
      fontWeight: 500,
    },
  },
  calendarWrapper: {
    flex: 1,
    minHeight: 0,
    overflow: "auto",
    ...theme.scrollbarStyles,
  },
  calendar: {
    display: "grid",
    gridTemplateColumns: "repeat(7, 1fr)",
    gap: "4px",
    padding: theme.spacing(1),
    height: "fit-content",
  },
  day: {
    position: "relative",
    height: "120px",
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 8,
    backgroundColor: theme.palette.background.paper,
    "&.past": {
      backgroundColor: theme.palette.action.hover,
    },
    "&.today": {
      backgroundColor: theme.palette.success.light,
      borderColor: theme.palette.success.main,
    },
  },
  dayNumber: {
    position: "absolute",
    top: 4,
    right: 4,
    fontSize: "0.8rem",
    color: theme.palette.text.secondary,
  },
  messageContainer: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    maxHeight: "90px",
    overflowY: "auto",
    ...theme.scrollbarStyles,
    "& > div": {
      color: theme.palette.getContrastText(theme.palette.background.paper),
    }
  },
  messageBadge: {
    padding: "2px 6px",
    borderRadius: 13,
    fontSize: "0.5rem",
    color: "#fff",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    cursor: "pointer",
  },
}));

const ScheduledMessagesCalendar = () => {
  const classes = useStyles();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const { data } = await api.get("/scheduledMessages");
        const messagesWithDetails = await Promise.all(
          data.map(async (message) => {
            try {
              const [contact, user, queue] = await Promise.all([
                api.get(`/contacts/${message.contactId}`),
                api.get(`/users/${message.userId}`),
                message.queueId ? api.get(`/queue/${message.queueId}`) : Promise.resolve({ data: null })
              ]);
              
              return {
                ...message,
                contact: contact.data,
                user: user.data,
                queue: queue.data
              };
            } catch (err) {
              console.error("Erro ao buscar detalhes da mensagem:", err);
              return message;
            }
          })
        );
        setMessages(messagesWithDetails);
      } catch (err) {
        console.error("Erro ao buscar mensagens agendadas:", err);
      }
    };
    fetchMessages();
  }, []);

  const getDaysInMonth = (date) => {
    const daysInMonth = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).getDate();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
    return { daysInMonth, firstDay };
  };

  const handlePreviousMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1));
  };

  const handleNextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1));
  };

  const renderMessageBadge = (message) => {
    const tooltipContent = [
      {
        label: "Data",
        value: new Date(message.scheduledAt).toLocaleString('pt-BR')
      },
      {
        label: "Contato", 
        value: message.contact?.name || 'Sem nome'
      },
      {
        label: "Usuário",
        value: message.user?.name || 'Sem usuário'
      },
      {
        label: "Setor",
        value: message.queue?.name || 'Sem setor'
      },
      {
        label: "Mensagem",
        value: message.message || 'Sem mensagem' 
      }
    ];
  
    return (
      <Tooltip
        key={message.id}
        title={
          <div style={{
            padding: '8px',
            maxWidth: '300px',
            fontSize: '0.875rem'
          }}>
            {tooltipContent.map((item, index) => (
              <div 
                key={index}
                style={{
                  display: 'flex',
                  marginBottom: index !== tooltipContent.length - 1 ? '4px' : 0
                }}
              >
                <span style={{ 
                  fontWeight: 500,
                  minWidth: '70px',
                  marginRight: '8px',
                  color: 'rgba(255, 255, 255, 0.7)'
                }}>
                  {item.label}:
                </span>
                <span style={{
                  color: '#fff',
                  wordBreak: 'break-word'
                }}>
                  {item.value}
                </span>
              </div>
            ))}
          </div>
        }
        placement="top"
        arrow
      >
        <div
          className={classes.messageBadge}
          style={{ 
            backgroundColor: message.queue?.color || "#7C7C7C",
            cursor: "pointer"
          }}
        >
          {message.contact?.name || "Sem nome"}
        </div>
      </Tooltip>
    );
  };

  const renderCalendar = () => {
    const today = new Date();
    const { daysInMonth, firstDay } = getDaysInMonth(currentDate);
    const days = [];

    for (let i = 0; i < firstDay; i++) {
      days.push(<div key={`empty-${i}`} className={classes.day}></div>);
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        day
      );
      const isPast = date < new Date(today.setHours(0, 0, 0, 0));
      const isToday = date.toDateString() === new Date().toDateString();

      const dayMessages = messages.filter((message) => {
        const messageDate = new Date(message.scheduledAt);
        return (
          messageDate.getDate() === day &&
          messageDate.getMonth() === currentDate.getMonth() &&
          messageDate.getFullYear() === currentDate.getFullYear()
        );
      });

      days.push(
        <div
          key={day}
          className={`${classes.day} ${isPast ? "past" : ""} ${
            isToday ? "today" : ""
          }`}
        >
          <span className={classes.dayNumber}>{day}</span>
          <div className={classes.messageContainer}>
            {dayMessages.map(renderMessageBadge)}
          </div>
        </div>
      );
    }

    return days;
  };

  const weekDays = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];

  return (
    <div className={classes.calendarContainer}>
      <Title>{i18n.t("dashboard.scheduledMessages.title")}</Title>
      <Box className={classes.header}>
        <Typography variant="h6">
          {currentDate.toLocaleDateString("pt-BR", {
            month: "long",
            year: "numeric",
          })}
        </Typography>
        <div>
          <IconButton onClick={handlePreviousMonth}>
            <CaretLeft />
          </IconButton>
          <IconButton onClick={handleNextMonth}>
            <CaretRight />
          </IconButton>
        </div>
      </Box>
      <div className={classes.weekDays}>
        {weekDays.map((day) => (
          <div key={day}>{day}</div>
        ))}
      </div>
      <div className={classes.calendarWrapper}>
        <div className={classes.calendar}>{renderCalendar()}</div>
      </div>
    </div>
  );
};

export default ScheduledMessagesCalendar;