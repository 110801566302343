import React from "react";
import { Tabs, Tab, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  tabsContainer: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 13,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100vw',
      overflow: 'auto'
    }
  },
  tabs: {
    "& .MuiTab-root": {
      minWidth: 40,
      padding: "6px 12px",
      [theme.breakpoints.down('sm')]: {
        padding: "6px 8px",
      }
    }
  }
}));

const AlphabetTabs = ({ currentTab, onChange }) => {
  const classes = useStyles();
  const tabs = ["all", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", 
                "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "0-9"];

  return (
    <div className={classes.tabsContainer}>
      <Tabs 
        value={currentTab}
        onChange={(e, newValue) => onChange(newValue)}
        variant="scrollable"
        scrollButtons="auto"
        className={classes.tabs}
      >
        {tabs.map((tab) => (
          <Tab 
            key={tab} 
            label={tab === "all" ? "Tudo" : tab}
            value={tab}
          />
        ))}
      </Tabs>
    </div>
  );
};

export default AlphabetTabs;