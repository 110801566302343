import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

import {
  Button,
  CircularProgress,
  Drawer,
  Typography,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Switch,
  TextField
} from "@material-ui/core";
import ColorLensIcon from '@material-ui/icons/ColorLens';
import ColorPicker from "../ColorPicker";

import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import QueueSelect from "../QueueSelect";

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 400,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  drawerHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(3)
  },
  drawerTitle: {
    fontWeight: 600,
    fontSize: '1.4rem'
  },
  colorPreview: {
    width: 20,
    height: 20,
    border: '1px solid rgba(0, 0, 0, 0.23)',
  },
  colorPicker: {
    position: 'absolute',
    zIndex: 2,
  },
  btnWrapper: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    display: "flex", 
    justifyContent: "flex-end",
    gap: theme.spacing(1)
  },
  button: {
    marginTop: 16,
    borderRadius: 13
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    marginBottom: theme.spacing(2),
    width: '100%',
    '& .MuiOutlinedInput-root': {
      height: 56,
      borderRadius: 4,
    }
  },
  switchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: theme.spacing(2),
  }
}));

const SessionSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
});

const WhatsAppModal = ({ open, onClose, whatsAppId, onSave }) => {
  const classes = useStyles();
  const initialState = {
    name: "",
    greetingMessage: "",
    farewellMessage: "",
    isDefault: false,
    isDisplay: false
  };
  
  const [whatsApp, setWhatsApp] = useState(initialState);
  const [selectedQueueIds, setSelectedQueueIds] = useState([]);
  const [isHubSelected, setIsHubSelected] = useState(false);
  const [availableChannels, setAvailableChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState("");
  const [color, setColor] = useState("#4d04a4");
  const [showColorPicker, setShowColorPicker] = useState(false);


  useEffect(() => {
    const fetchSession = async () => {
      if (!whatsAppId) return;

      try {
        const { data } = await api.get(`whatsapp/${whatsAppId}`);
        setWhatsApp(data);
        setColor(data?.color)
        const whatsQueueIds = data.queues?.map(queue => queue.id);
        setSelectedQueueIds(whatsQueueIds);
      } catch (err) {
        toastError(err);
      }
    };
    fetchSession();
  }, [whatsAppId]);

  const handleSaveWhatsApp = async values => {
    const whatsappData = { ...values, queueIds: selectedQueueIds, color: color };
    try {
      if (isHubSelected && selectedChannel) {
        const selectedChannelObj = availableChannels.find(
          channel => channel.id === selectedChannel
        );

        if (selectedChannelObj) {
          const channels = [selectedChannelObj];
          await api.post("/hub-channel/", {
            ...whatsappData,
            channels
          });
        }
      } else {
        if (whatsAppId) {
          await api.put(`/whatsapp/${whatsAppId}`, whatsappData);
        } else {
          await api.post("/whatsapp", whatsappData);
        }
      }
      handleClose();
      onSave(); 
      toast.success(i18n.t("whatsappModal.success"));
    } catch (err) {
      toastError(err);
      throw err;
    }
  };

  const handleClose = () => {
    onClose();
    setWhatsApp(initialState);
    setIsHubSelected(false);
    setSelectedChannel("");
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.drawer }}
    >
      <Typography className={classes.drawerTitle}>
        {whatsAppId
          ? i18n.t("whatsappModal.title.edit")
          : i18n.t("whatsappModal.title.add")}
      </Typography>

      <Formik
        initialValues={whatsApp}
        enableReinitialize={true}
        validationSchema={SessionSchema}
        onSubmit={async (values, actions) => {
          try {
            await handleSaveWhatsApp(values);
            actions.setSubmitting(false);
          } catch (error) {
            actions.setSubmitting(false);
          }
        }}
      >
        {({ values, touched, errors, isSubmitting }) => (
          <Form style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <Field
              as={TextField}
              label={i18n.t("whatsappModal.form.name")}
              name="name"
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
              variant="outlined"
              margin="dense"
              className={classes.formControl}
            />

            <Field
              name="color"
              component={() => (
                <ColorPicker
                  color={color}
                  onChange={setColor}
                />
              )}
            />

            <div className={classes.switchContainer}>
              {!isHubSelected && (
                <>
                  <FormControlLabel
                    control={
                      <Field
                        as={Switch}
                        color="primary"
                        name="isDefault"
                        checked={values.isDefault}
                      />
                    }
                    label={i18n.t("whatsappModal.form.default")}
                  />
                  <FormControlLabel
                    control={
                      <Field
                        as={Switch}
                        color="primary"
                        name="isDisplay"
                        checked={values.isDisplay}
                      />
                    }
                    label={i18n.t("whatsappModal.form.display")}
                  />
                </>
              )}
            </div>

            {isHubSelected && (
              <Select
                label="Selecionar Canal"
                fullWidth
                value={selectedChannel || ""}
                onChange={e => setSelectedChannel(e.target.value)}
                variant="outlined"
                className={classes.formControl}
              >
                <MenuItem value="" disabled>
                  Selecione um canal
                </MenuItem>
                {availableChannels.map(channel => (
                  <MenuItem key={channel.id} value={channel.id}>
                    {channel.name}
                  </MenuItem>
                ))}
              </Select>
            )}

            {!isHubSelected && (
              <>
                <Field
                  as={TextField}
                  label={i18n.t("queueModal.form.greetingMessage")}
                  type="greetingMessage"
                  multiline
                  minRows={6}
                  fullWidth
                  name="greetingMessage"
                  error={touched.greetingMessage && Boolean(errors.greetingMessage)}
                  helperText={touched.greetingMessage && errors.greetingMessage}
                  variant="outlined"
                  margin="dense"
                />

                <Field
                  as={TextField}
                  label={i18n.t("whatsappModal.form.farewellMessage")}
                  type="farewellMessage"
                  multiline
                  minRows={6}
                  fullWidth
                  name="farewellMessage"
                  error={touched.farewellMessage && Boolean(errors.farewellMessage)}
                  helperText={touched.farewellMessage && errors.farewellMessage}
                  variant="outlined"
                  margin="dense"
                />

                <QueueSelect
                  selectedQueueIds={selectedQueueIds}
                  onChange={selectedIds => setSelectedQueueIds(selectedIds)}
                  className={classes.formControl}
                />
              </>
            )}

            <div className={classes.btnWrapper}>
              <Button
                onClick={handleClose}
                color="secondary"
                disabled={isSubmitting}
                variant="outlined"
                className={classes.button}
              >
                {i18n.t("whatsappModal.buttons.cancel")}
              </Button>
              <Button
                type="submit"
                color="primary"
                disabled={isSubmitting}
                variant="contained"
                className={classes.button}
              >
                {whatsAppId
                  ? i18n.t("whatsappModal.buttons.okEdit")
                  : i18n.t("whatsappModal.buttons.okAdd")}
                {isSubmitting && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};

export default React.memo(WhatsAppModal);