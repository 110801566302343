import React, { useState } from 'react';
import Avatar from 'react-avatar';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { Image } from 'phosphor-react';
import api from '../../services/api';
import toastError from '../../errors/toastError';
import { toast } from 'react-toastify';
import { i18n } from '../../translate/i18n';
import PropTypes from 'prop-types';
import { getAvatarUrl } from '../../utils/avatarUtils';

const useStyles = makeStyles(theme => ({
  avatar: {
    margin: theme.spacing(1),
    cursor: 'pointer',
    position: 'relative',
  },
  editIcon: {
    position: 'absolute',
    bottom: -4,
    right: -4,
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50%',
    padding: 4
  }
}));

const UserAvatar = ({ user, setUser, size = 40, className = '' }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  if (!user) {
    console.error("User is not defined");
    return null;
  }

  const avatarUrl = getAvatarUrl(user);

  const handleOpenMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleUploadFile = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('avatar', file);

    try {
      const { data } = await api.put(`/users/${user.id}/avatar`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      user.avatar = data.avatar;
      setUser(prevState => ({ ...prevState, avatar: data.avatar }));
      toast.success(i18n.t('userModal.success.avatar'));
    } catch (err) {
      console.error(err);
      toastError(err);
    }
    handleCloseMenu();
  };

  const handleDeleteAvatar = async () => {
    try {
      await api.delete(`/users/${user.id}/avatar`);
      toast.success(i18n.t('userModal.success.avatarDelete'));
    } catch (err) {
      toastError(err);
    }
    handleCloseMenu();
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <Avatar
        name={user?.name || ""}
        size={size}
        round={true}
        src={avatarUrl}
        className={`${classes.avatar} ${className}`}
        onClick={handleOpenMenu}
        key={user?.id}
      />
      <IconButton 
        size="small" 
        className={classes.editIcon}
        onClick={handleOpenMenu}
      >
        <Image size={20} />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <label htmlFor="upload-avatar">
          <MenuItem component="span">
            {i18n.t("mainDrawer.appBar.user.changeImage")}
            <input
              type="file"
              id="upload-avatar"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={handleUploadFile}
            />
          </MenuItem>
        </label>
        {avatarUrl && (
          <MenuItem onClick={handleDeleteAvatar}>
            {i18n.t("mainDrawer.appBar.user.deleteImage")}
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

UserAvatar.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    avatar: PropTypes.string,
  }).isRequired,
  setUser: PropTypes.func.isRequired,
  size: PropTypes.number,
  className: PropTypes.string,
};

export default UserAvatar;