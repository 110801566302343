import React, { useContext, useEffect, useState } from "react";
import {
    Badge,
    Button,
    FormControlLabel,
    makeStyles,
    Paper,
    Switch,
    Tab,
    Tabs,
    InputAdornment,
    TextField,
    Tooltip
} from "@material-ui/core";
import {
    Archive,
    HourglassMedium,
    Cards,
    MagnifyingGlass,
    UsersFour,
    User
} from "phosphor-react";
import { Can } from "../Can";
import NewTicketModal from "../NewTicketModal";
import TabPanel from "../TabPanel";
import TicketsList from "../TicketsList";
import TicketsQueueSelect from "../TicketsQueueSelect";
import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
    ticketsWrapper: {
        position: "relative",
        display: "flex",
        height: "100%",
        flexDirection: "column",
        overflow: "hidden",
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    },
    tabsHeader: {
        flex: "none",
        backgroundColor: theme.palette.background.default,
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    },
    settingsIcon: {
        alignSelf: "center",
        marginLeft: "auto",
        padding: 8,
    },
    tab: {
        minWidth: 120,
        width: 120,
        fontWeight: "bold",
    },
    ticketOptionsBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    },
    searchInputWrapper: {
        flex: 1,
        backgroundColor: theme.palette.background.default,
        display: "flex",
        borderRadius: "13px",
        padding: 4,
        marginRight: theme.spacing(1),
    },
    searchIcon: {
        color: theme.palette.primary.main,
        marginLeft: 6,
        marginRight: 6,
        alignSelf: "center",
    },
    searchInput: {
        flex: 1,
        border: "none",
        borderRadius: "13px",
        padding: "10px",
        outline: "none",
    },
    badge: {
        right: 0,
    },
    show: {
        display: "block",
    },
    hide: {
        display: "none !important",
    },
    searchContainer: {
        display: "flex",
        padding: "10px",
        borderBottom: "1px solid rgba(0, 0, 0, .12)",
        borderTop: "1px solid rgba(0, 0, 0, .12)",
        backgroundColor: theme.palette.background.default,
    },
    newButton: {
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
        },
    },
}));

const TicketsManager = () => {
    const classes = useStyles();
    const [searchParam, setSearchParam] = useState("");
    const [tab, setTab] = useState("open");
    const [tabOpen] = useState("open");
    const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
    const [showAllTickets, setShowAllTickets] = useState(false);
    const { user } = useContext(AuthContext);
    const [openCount, setOpenCount] = useState(0);
    const [pendingCount, setPendingCount] = useState(0);
    const userQueueIds = user.queues.map((q) => q.id);
    const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);

    useEffect(() => {
        if (user.profile.toUpperCase() === "ADMIN") {
            setShowAllTickets(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSearch = (e) => {
        const searchedTerm = e.target.value.toLowerCase();
        setSearchParam(searchedTerm);
        if (searchedTerm === "") {
            setTab("open");
        } else if (tab !== "search") {
            setTab("search");
        }
    };

    const handleChangeTab = (e, newValue) => {
        setTab(newValue);
    };

    const applyPanelStyle = (status) => {
        if (tabOpen !== status) {
            return { width: 0, height: 0 };
        }
    };

    return (
        <Paper elevation={0} variant="outlined" className={classes.ticketsWrapper}>
            <NewTicketModal
                modalOpen={newTicketModalOpen}
                onClose={(e) => setNewTicketModalOpen(false)}
            />
            <Paper elevation={0} square className={classes.tabsHeader}>
                <Tabs
                    value={tab}
                    onChange={handleChangeTab}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="icon label tabs example"
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: '#4d04a4',
                        },
                    }}
                >
                    <Tab
                        value={"open"}
                        icon={<Cards size={28} weight="light" />}
                        label={
                            <Badge
                                className={classes.badge}
                                badgeContent={openCount}
                                overlap="rectangular"
                                max={9999}
                                color="secondary"
                            >
                                {i18n.t("tickets.tabs.open.title")}
                            </Badge>
                        }
                        classes={{ root: classes.tab }}
                    />
                    <Tab
                        value={"pending"}
                        icon={<HourglassMedium size={28} weight="light" />}
                        label={
                            <Badge
                                className={classes.badge}
                                badgeContent={pendingCount}
                                overlap="rectangular"
                                max={9999}
                                color="secondary"
                            >
                                {i18n.t("ticketsList.pendingHeader")}
                            </Badge>
                        }
                        classes={{ root: classes.tab }}
                    />
                    <Tab
                        value={"closed"}
                        icon={<Archive size={28} weight="light" />}
                        label={i18n.t("tickets.tabs.closed.title")}
                        classes={{ root: classes.tab }}
                    />
                </Tabs>
            </Paper>
            <Paper elevation={0} square className={classes.searchContainer}>
                <TextField
                    placeholder={i18n.t("tickets.search.placeholder")}
                    variant="outlined"
                    fullWidth
                    value={searchParam}
                    onChange={handleSearch}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MagnifyingGlass size={24} className={classes.searchIcon} />
                            </InputAdornment>
                        ),
                    }}
                />
            </Paper>
            <Paper square elevation={0} className={classes.ticketOptionsBox}>
                <Button
                    variant="contained"
                    className={classes.newButton}
                    onClick={() => setNewTicketModalOpen(true)}
                >
                    {i18n.t("ticketsManager.buttons.newTicket")}
                </Button>
                <Can
                    role={user.profile}
                    perform="tickets-manager:showall"
                    yes={() => (
                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <Tooltip title={i18n.t("tickets.buttons.mine")}>
                                <User size={24} />
                            </Tooltip>
                            
                            <Switch
                                size="small"
                                checked={showAllTickets}
                                onChange={() => setShowAllTickets((prevState) => !prevState)}
                                name="showAllTickets"
                                color="primary"
                            />
                            
                            <Tooltip title={i18n.t("tickets.buttons.showAll")}>
                                <UsersFour size={24} />
                            </Tooltip>
                        </div>
                    )}
                />
                <TicketsQueueSelect
                    style={{ marginLeft: 6 }}
                    selectedQueueIds={selectedQueueIds}
                    userQueues={user?.queues}
                    onChange={(values) => setSelectedQueueIds(values)}
                />
            </Paper>
            <TabPanel value={tab} name="open" className={classes.ticketsWrapper}>
                <Paper className={classes.ticketsWrapper}>
                    <TicketsList
                        status="open"
                        showAll={showAllTickets}
                        selectedQueueIds={selectedQueueIds}
                        updateCount={(val) => setOpenCount(val)}
                        style={applyPanelStyle("open")}
                    />
                    <TicketsList
                        status="pending"
                        selectedQueueIds={selectedQueueIds}
                        updateCount={(val) => setPendingCount(val)}
                        style={applyPanelStyle("pending")}
                    />
                </Paper>
            </TabPanel>
            <TabPanel value={tab} name="pending" className={classes.ticketsWrapper}>
                <TicketsList
                    status="pending"
                    showAll={true}
                    selectedQueueIds={selectedQueueIds}
                />
            </TabPanel>
            <TabPanel value={tab} name="closed" className={classes.ticketsWrapper}>
                <TicketsList
                    status="closed"
                    showAll={true}
                    selectedQueueIds={selectedQueueIds}
                />
            </TabPanel>
            <TabPanel value={tab} name="search" className={classes.ticketsWrapper}>
                <TicketsList
                    searchParam={searchParam}
                    showAll={true}
                    selectedQueueIds={selectedQueueIds}
                />
            </TabPanel>
        </Paper>
    );
};

export default TicketsManager;